.chatonline{
  max-height: 650px;
  @include media-breakpoint-down(xs){
    max-height: 550px!important;
  }
  li{
    position: relative;
    .label{
      color: #fff;
      display: inline-block;
      position: absolute;
      left: 6px;
      top: 8px;
      font-size: 10px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      line-height: 20px;
      padding: 0;
      text-align: center;
      z-index: 1;
    }
    span {
      color: #555;
      display: block;
      overflow: hidden;
      font-size: 14px;
      line-height: 1.3;
      small {
        display: block;
        font-size: 10px;
        margin-top: 2px;
      }
    }
    a{
      padding: 13px 0;
      float: left;
      width: 100%;
      border-bottom: 1px solid $border-color;
    }
  }
  .user-text{
    margin-right: 10px;
    float: left;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    line-height: 30px;
    font-size: 13px;
  }
  img {
    margin-right: 10px;
    float: left;
    width: 30px;
    border-radius: 2px;
    border-radius: 50%;
  }

  .chat-item{
    &__row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 4px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &__title{
      font-size: 14px;
      color: $body-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__date{
      font-size: 12px;
      color: #888;
    }
    &__last-message{
      font-size: 12px;
      color: #888;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      > span{
        font-size: 12px;
        display: inline;
      }
    }
    span.label{
      position: relative;
      left: auto;
      top: auto;
    }
  }
}


.topbar{
  .top-navbar{
    .mailbox {
      width: 300px;
      ul {
        padding: 0;
        li {
          list-style: none;
        }
      }
    }
  }
}


.mailbox{
  ul{
    li{
      .drop-title {
        font-weight: 600;
        padding: 11px 15px 15px;
        border-bottom: 1px solid rgba(120, 130, 140, 0.13);
      }
      .nav-link {
        border-top: 1px solid rgba(120, 130, 140, 0.13);
        padding-top: 15px;
      }
    }
  }

  .active {
    .message-center__icon {
      border-color: $success;
      background-color: $success;
      color: #fff;
    }
  }

  .message-center {
    max-height: 200px;
    overflow: auto;
    position: relative;

    &__icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      border: 1px solid #ddd;

      .mdi {
        font-size: 16px !important;
      }
    }

    a {
      border-bottom: 1px solid $gray-200;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 9px 15px;
      color: $body-color;
      &:hover {
        background: $gray-100;
      }
      div {
        white-space: normal;
        margin-right: 10px;
        &.btn-circle{
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
        }
        .mdi{
          font-size: 18px;
        }
      }
      .mail-contnet {
        display: block;
        // width: 75%;
        flex: 1;
        margin-right: 0;
        vertical-align: middle;
        overflow: hidden;
        h5 {
          margin: 0;
          font-size: 14px;
          font-weight: 600;
        }
        .name {
          font-size: 12px;
          color: $body-color;
          margin-top: 2px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .mail-desc {
          text-align: left;
        }
        .mail-desc,
        .time {
          font-size: 12px;
          display: block;
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          color: $gray-600;
          white-space: nowrap;
        }
      }
      .user-img {
        width: 40px;
        position: relative;
        display: inline-block;
        margin: 0 10px 8px 0;
        .online {
          background: $success;
        }
        .busy {
          background: $danger;
        }
        .away {
          background: $warning;
        }
        .offline {
          background: $warning;
        }
        img{
          width: 100%;
          border-radius: 50%;
        }
        .user-text{
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
        }
        .profile-status {
          border: 2px solid #ffffff;
          border-radius: 50%;
          display: inline-block;
          height: 10px;
          left: 30px;
          position: absolute;
          top: 1px;
          width: 10px;
        }
      }
    }
  }
}

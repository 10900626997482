@media (min-width: 768px) {
  .navbar-header {
    width: 240px;
    flex-shrink: 0;
  }

  .navbar-header .navbar-brand {
    padding-top: 0;
  }
  /*This is for the breeadcrumd*/
  .page-titles .breadcrumb {
    float: right;
  }

  .card-group .card:first-child,
  .card-group .card:not(:first-child):not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.03);
  }

  .mini-sidebar .page-wrapper {
    margin-left: 70px;
  }

  .flex-wrap {
    flex-wrap: nowrap !important;
  }
}
@media (max-width: 767px) {
  .container-fluid {
    padding: 25px 15px;
  }
  /*Header*/
  .topbar {
    position: fixed;
    width: 100%;
  }

  .topbar .top-navbar {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .topbar .top-navbar .navbar-collapse {
    display: flex;
    width: 100%;
  }

  .topbar .top-navbar .navbar-nav {
    flex-direction: row;
  }

  .topbar .top-navbar .navbar-nav > .nav-item.show {
    position: static;
  }

  .topbar .top-navbar .navbar-nav > .nav-item.show .dropdown-menu {
    width: 100%;
    margin-top: 0;
  }

  .topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
    // padding-left: 0.50rem;
    // padding-right: 0.50rem;
  }

  .topbar .top-navbar .navbar-nav .dropdown-menu {
    position: absolute;
  }
  /*Sidebar and wrapper*/
  .mini-sidebar .page-wrapper {
    margin-left: 0;
    padding-top: 70px;
  }

  .comment-text .comment-footer .action-icons {
    display: block;
    padding: 10px 0;
  }
  /*This is for chat page*/
  .chat-main-box .chat-left-aside {
    left: -250px;
    position: absolute;
    transition: 0.5s ease-in;
    background: #ffffff;
  }

  .chat-main-box .chat-left-aside.open-pnl {
    left: 0;
    bottom: 0;
  }

  .chat-main-box .chat-left-aside .open-panel {
    display: block;
  }

  .chat-main-box .chat-right-aside {
    width: 100%;
  }
  /*Contact app page*/
  .left-aside {
    width: 100%;
    position: relative;
    border: 0;
  }

  .right-aside {
    margin-left: 0;
  }

  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    -webkit-flex-wrap: wrap !important;
  }
}

.modal-header {
  background-color: $primary;
  color: #fff;
  @include media-breakpoint-down(xs) {
    padding: 1rem 1.5rem;
  }
  .close {
    color: #fff;
    font-size: 27px;
    text-shadow: none;
    opacity: 1;
    &:focus {
      outline: none;
    }
  }
}

.modal-title {
  font-weight: font-weight(bold);
  font-size: 18px;
}

.modal-body {
  @include media-breakpoint-down(xs) {
    padding: 1rem 1.5rem;
  }
}
.dark-modal {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.5;
}

.navbar{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
}

.header-mobile-toggle{
  .navbar-nav__second{
    opacity: 1;
    overflow: visible;
    top: 100%;
  }
}


.navbar-nav{
  float: left;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  &__first{
    @include media-breakpoint-down(xs){
      flex: 1;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  &__second{
    @include media-breakpoint-down(xs){
      z-index: -100;
      transition: all ease 0.25s;
      opacity: 0;
      top: 0;
      overflow: hidden;
      position: absolute;
      background: $primary;
      left: 0;
      right: 0;
      box-shadow: 0 1px 1px 0 rgba(0,0,0,.05),
                  0 2px 2px 0 rgba(0,0,0,.05),
                  0 4px 4px 0 rgba(0,0,0,.05),
                  0 8px 8px 0 rgba(0,0,0,.05);
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0 15px;
      .nav-item{

      }
    }
  }

  + .navbar-nav{
    float: none;
    float: right;
  }
  .dropdown-menu {
    position: static;
    float: none;
  }
  .nav-item{
    float: left;
    > .btn{
      margin-left: 8px;
      margin-right: 8px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: .536em;
      @include media-breakpoint-down(xs) {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        padding: 0;
      }
      span{
        vertical-align: middle;
        line-height: 1;
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
      .mdi{
        font-size: 16px;
        vertical-align: middle;
      }
    }
  }
  .nav-link{
    color: rgba(0,0,0,.5);
    padding-right: 0;
    padding-left: 0;
  }
}
.nav-link{
  display: block;
  padding: .5rem 1rem;
}


.navbar-brand{
  color: $body-color;
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-left: 8px;
  // .logo-v{
  //   display: none;
  //   vertical-align: middle;
  //   width: 42px;
  // }
  .logo{
    display: inline-block;
    vertical-align: middle;
    min-width: 100px;
  }
}

.navbar-collapse{
  flex-basis: 100%;
  align-items: center;
}

.navbar-header{
  float: left;
  display: flex;
  align-items: center;
  @media(min-width: 768px){
    width: 240px;
    flex-shrink: 0;
    .navbar-brand{
      padding-top: 0;
    }
  }
}
.navbar-expand-md{
  @media(min-width: 768px){
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    .navbar-collapse{
      display: flex!important;
    }
    .navbar-nav{
      flex-direction: row;
      .nav-link{
        padding-right: .5rem;
        padding-left: .5rem;
      }
      .dropdown-menu {
        position: absolute;
      }
      .dropdown-menu-right {
        right: 0;
        left: auto;
      }
    }
  }
}


.navbar-notify{
  display: flex;
  // align-self: stretch;
  margin-left: auto;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  @include media-breakpoint-down(sm){
    display: none;
  }
  ~ .navbar-nav{
    margin-left: 0;
    @include media-breakpoint-down(sm){
      margin-left: auto;
    }
  }
  &__item{
    border-right: 1px solid $border-color;
    &:last-child{
      border-right: 0;
    }
  }
  &__inner{
    display: flex;
    flex-direction: column;
  }
  &__title{
    // order: 1;
    font-size: 12px;
    line-height: 1.2;
    color: #888;
    // padding-right: 8px;
  }
  &__value{
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    color: $danger;
    white-space: nowrap;
  }
  &__link{
    height: 100%;
    display: flex!important;
    flex-direction: column;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    &:hover{
      text-decoration: none;
      .navbar-notify__title{
        color: $secondary;
      }
      background-color: $gray-100;
    }
  }
}

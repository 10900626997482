.calc{
  margin-bottom: 30px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
  border: 1px solid rgba(0,0,0,.125);
  position: relative;
  height: calc(100vh - 90px);
  &.is-fullscreen{
    z-index: 999;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border: 0;
    box-shadow: none;
    .calc__main{
      height: calc(100% - 60px);
    }
  }
  &__header{
    z-index: 1;
    position: relative;
    height: 60px;
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
    .card-title{
      margin-bottom: 0;
    }
    .d-flex{
      align-items: center;
    }
  }
  &__main{
    position: relative;
    background-color: #fff;
    height: calc(100% - 60px);
    overflow: hidden;
  }
  &__iframe{
    border: 0;
  }
}

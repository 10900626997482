.qt-loader {
  position: relative;
  z-index: 999;
  &:before {
    content: "";
    top: 57%;
    background: 0 0;
    margin-top: -0.6rem;
    opacity: 0.75;
    left: 0;
    box-sizing: border-box;

    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    border-radius: 50%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    animation: clockwise 0.5s linear infinite;
  }

  &.qt-loader-center {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  // left
  &.qt-loader-left {
    padding-left: 40px !important;
    &:before {
      right: auto;
      left: 0;
      margin-left: 13px;
    }
  }

  // right
  &.qt-loader-right {
    padding-right: 40px;
    &:before {
      left: auto;
      right: 0;
      margin-right: 13px;
    }
  }

  // primary
  &.qt-loader-primary {
    &:before {
      border: 2px solid $primary;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }

  // secondary
  &.qt-loader-secondary {
    &:before {
      border: 2px solid $secondary;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }

  // success
  &.qt-loader-success {
    &:before {
      border: 2px solid $success;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }

  // danger
  &.qt-loader-danger {
    &:before {
      border: 2px solid $danger;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }

  // info
  &.qt-loader-info {
    &:before {
      border: 2px solid $info;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }

  // warning
  &.qt-loader-warning {
    &:before {
      border: 2px solid $warning;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }

  // default
  &.qt-loader-default {
    &:before {
      border: 2px solid $default;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }

  // hover
  &:hover:not(.disabled):not(:disabled),
  &:active:focus,
  &:active {
    &.qt-loader-primary {
      &:before {
        border: 2px solid #fff;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
    &.qt-loader-secondary {
      &:before {
        border: 2px solid #fff;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
    &.qt-loader-success {
      &:before {
        border: 2px solid #fff;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
    &.qt-loader-danger {
      &:before {
        border: 2px solid #fff;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
    &.qt-loader-info {
      &:before {
        border: 2px solid #fff;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
    &.qt-loader-warning {
      &:before {
        border: 2px solid #fff;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }
}

.btn-outline {
  &-dark,
  &-primary,
  &-danger,
  &-warning,
  &-info,
  &-secondary,
  &-success {
    &:focus {
      // primary
      &.qt-loader-primary {
        &:before {
          border: 2px solid $primary;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
      }

      // secondary
      &.qt-loader-secondary {
        &:before {
          border: 2px solid $secondary;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
      }

      // success
      &.qt-loader-success {
        &:before {
          border: 2px solid $success;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
      }

      // danger
      &.qt-loader-danger {
        &:before {
          border: 2px solid $danger;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
      }

      // info
      &.qt-loader-info {
        &:before {
          border: 2px solid $info;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
      }

      // warning
      &.qt-loader-warning {
        &:before {
          border: 2px solid $warning;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
      }

      // default
      &.qt-loader-default {
        &:before {
          border: 2px solid $default;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
      }
    }
  }
}

@keyframes clockwise {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

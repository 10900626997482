$ml: () !default;
$ml: map-merge((
  0:  $primary,
  1:  #f44336,
  2:  #4CAF50,
  3:  #2196F3,
  4:  #FFB300,
  5:  #673AB7,
  6:  #607D8B
), $ml);

$cars: () !default;
$cars: map-merge((
  1:  #f44336,
  2:  #4CAF50,
  3:  #2196F3,
  4:  #FFB300,
  5:  #673AB7,
  6:  #607D8B
), $cars);

// @function color($key: "1") {
//   @return map-get($colors, $key);
// }

@each $i, $color in $ml {
  .color-#{$i} {
    // &.route-list__item{
    //   border: 1px solid #{$color};
    // }
    .route-list__collapse,
    .delivery-list__number{
      background-color: #{$color};
      color: #fff;
    }
  }
}

@each $i, $color in $cars {
  .car-#{$i} {
    color: #{$color};
  }
  .car-color-#{$i} {
    color: #{$color};
  }
}

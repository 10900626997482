.message-box ul li .drop-title {
  font-weight: 600;
  padding: 11px 20px 15px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.message-box ul li .nav-link {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  padding-top: 15px;
}

.message-box .message-widget {
  position: relative;
}

.message-box .message-widget a {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  display: block;
  text-decoration: none;
  padding: 9px 15px;
}

.message-box .message-widget a:hover {
  background: #e9edf2;
}

.message-box .message-widget a:last-child {
  border-bottom: 0;
}

.message-box .message-widget a div {
  white-space: normal;
}

.message-box .message-widget a .user-img {
  width: 45px;
  position: relative;
  display: inline-block;
  margin: 0 10px 15px 0;
}

.message-box .message-widget a .user-img img {
  width: 100%;
}

.message-box .message-widget a .user-img .profile-status {
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  left: 33px;
  position: absolute;
  top: -1px;
  width: 10px;
}

.message-box .message-widget a .user-img .online {
  background: #06d79c;
}

.message-box .message-widget a .user-img .busy {
  background: #ef5350;
}

.message-box .message-widget a .user-img .away {
  background: #ffb22b;
}

.message-box .message-widget a .user-img .offline {
  background: #ffb22b;
}

.message-box .message-widget a .mail-contnet {
  display: inline-block;
  width: 70%;
  vertical-align: middle;
}

.message-box .message-widget a .mail-contnet h5 {
  margin: 5px 0 0;
}

.message-box .message-widget a .mail-contnet .mail-desc,
.message-box .message-widget a .mail-contnet .time {
  font-size: 12px;
  display: block;
  margin: 1px 0;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #67757c;
  white-space: nowrap;
}

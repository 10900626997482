.page-wrapper {
  background: $gray-100;
  // padding-bottom: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    margin-left: 240px;
  }
  @media (max-width: 1023px) {
    margin-left: 70px;
    transition: margin 0.2s ease-in;
  }
}

.container-fluid {
  padding: 20px 25px;
}

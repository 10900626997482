.single-column .left-sidebar {
  display: none;
}

.single-column .page-wrapper {
  margin-left: 0;
}

.fix-width {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

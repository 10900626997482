%c-btn {
  text-decoration: none;
  transition: 0.2s ease-out;
  box-shadow: 
    0 3px 1px -2px rgba(0, 0, 0, 0.14),
    0 2px 2px 0 rgba(0, 0, 0, 0.098),
    0 1px 5px 0 rgba(0, 0, 0, 0.084);
  background-image: none;

  &:focus,
  &:hover {
    text-decoration: none;
    outline: none;
    box-shadow: 
      0 2px 4px -1px rgba(0, 0, 0, 0.14), 
      0 4px 5px 0 rgba(0, 0, 0, 0.098), 
      0 1px 10px 0 rgba(0, 0, 0, 0.084);
  }
}

.bg-default{
  background-color: #e2e2e2;
}

.btn {
  font-weight: $btn-font-weight;
  font-size: 14px;
  line-height: 1.5;
  display: inline-block;
  padding-right: 16px;
  padding-left: 16px;
  margin: 0;
  text-align: center;
  box-shadow: 
    0 1px 1px 0 rgba(0, 0, 0, 0.05), 
    0 2px 2px 0 rgba(0, 0, 0, 0.05),
    0 4px 4px 0 rgba(0, 0, 0, 0.05);
  background-image: none;
  border-radius: $btn-border-radius;
  // border: 0;
  white-space: normal!important;
  word-wrap: break-word;
  user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  transition: 0.2s ease-out;
  @extend %c-btn;

  &,
  &:active {
    &:focus {}
  }

  &:focus,
  &:hover {
    // border: 0;
  }

  &:active {
    outline: 0;
    background-image: none;
  }
  &.disabled,
  &:disabled{
    cursor: not-allowed;
  }
}
.disabled{
  cursor: not-allowed;
  opacity: 0.5;
}
.btn-icon{
  box-shadow: none;
  border-radius: 50%!important;
  font-size: 24px;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: $body-color;
  border: 0 !important;
  &.disabled,
  &:disabled{
    cursor: not-allowed;
    opacity: 0.5;
  }
  &.btn-warning{
    color: #fff;
    &:hover{
      color: #fff;
    }
  }
  &:hover{
    color: $body-color;
  }
  &:after{
    display: none;
  }
}
.btn-circle {
  border-radius: 100%;
  width: 38px;
  height: 38px;
  padding: 8px;
  &.btn-sm {
    width: 34px;
    height: 34px;
    padding: 8px 10px;
    font-size: 14px;
  }
  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 23px;
  }
  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
  }
  &.right-side-toggle {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 25px;
    z-index: 10;
  }
}

.btn-close{
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  line-height: 0;
  outline: none;
  &:focus{
    outline: none;
  }
}

.btn.btn-cart{
  display: inline-block;
  width: auto;
  position: fixed!important;
  right: 0;
  top: 95px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
  width: 48px;
  height: 48px;
  line-height: 48px;
  .mdi{
    font-size: 24px;
  }
}

.btn-outline{
  &-dark,
  &-primary,
  &-danger,
  &-warning,
  &-info,
  &-secondary,
  &-success{
    box-shadow: none!important;
  }
}
.btn-outline-dark{
  color: darken($dark, 15%);
  &:hover {
    color: #fff;
  }
}

.btn.btn-primary{
  color: #fff;
}
.promo {
  margin-bottom: 2rem;
  // box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
  border-radius: $border-radius;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  @include media-breakpoint-down(sm){
    padding: 8px;
  }
  .swiper-slide {
    text-align: center;
    img {
      border-radius: $border-radius;
      width: 100%;
      height: 100%;
      aspect-ratio: 4/3;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    background-size: 10px;
    background-color: #fff;
    border-radius: 50%;
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23aaa'%2F%3E%3C%2Fsvg%3E");
  }
  .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23aaa'%2F%3E%3C%2Fsvg%3E");
  }
}

.rc-tree {
  margin: 0;
  padding: 0;
  .rc-tree-node-content-wrapper{
    transition: background-color ease 0.25s;
    border-radius: 2px;
    &:hover{
      background-color: $gray-200;
       color: #000;
    }
  }
  .rc-tree-switcher.rc-tree-switcher_open {
    ~ .rc-tree-node-content-wrapper{
      .rc-tree-title{
        font-weight: 600;
      }
    }
  }
  .active{
    .rc-tree-title{
      font-weight: 600;
    }
  }
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    // white-space: nowrap;
    outline: 0;
    font-size: 13px;
    background-repeat: repeat-y;
    background-position: 9px 0;
    background-size: 1px 2px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjZGMUQyOEUxMDdGNjExRTg5Mjg1QTVCMzJCMDFGOUNEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjZGMUQyOEUyMDdGNjExRTg5Mjg1QTVCMzJCMDFGOUNEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NkYxRDI4REYwN0Y2MTFFODkyODVBNUIzMkIwMUY5Q0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NkYxRDI4RTAwN0Y2MTFFODkyODVBNUIzMkIwMUY5Q0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5DpG+zAAAAFUlEQVR42mI4dOjQf4b///8zAAQYACErBkODA/GTAAAAAElFTkSuQmCC');
    &:before{
      position: absolute;
      top: 11px;
      left: 10px;
      width: 10px;
      height: 1px;
      content:"";
      display: block;
      background-position: 0 0;
      background-repeat: repeat-x;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAYAAAD0In+KAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkEwNjY5MjgyMDgwMjExRTg4MzA5RDVFRkM2M0ZBQkNBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkEwNjY5MjgzMDgwMjExRTg4MzA5RDVFRkM2M0ZBQkNBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTA2NjkyODAwODAyMTFFODgzMDlENUVGQzYzRkFCQ0EiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTA2NjkyODEwODAyMTFFODgzMDlENUVGQzYzRkFCQ0EiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5QpRyZAAAAFElEQVR42mL+//8/g7Oz83+AAAMAGGkEyVQFDCkAAAAASUVORK5CYII=');
    }
    .draggable {
      color: #333;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      /* Required to make elements draggable in old WebKit */
      -khtml-user-drag: element;
      -webkit-user-drag: element;
    }
    &.drag-over {
      > .draggable {
        background-color: #316ac5;
        color: white;
        border: 1px #316ac5 solid;
        opacity: 0.8;
      }
    }
    &.drag-over-gap-top {
      > .draggable {
        border-top: 2px blue solid;
      }
    }
    &.drag-over-gap-bottom {
      > .draggable {
        border-bottom: 2px blue solid;
      }
    }
    &.filter-node {
      > .rc-tree-node-content-wrapper {
        color: #a60000!important;
        font-weight: bold!important;
      }
    }
    ul {
      margin: 0;
      padding: 0 0 0 18px;
      > li{
        &:first-child{
          padding-top: 8px;
        }
        &:last-child{
          padding-bottom: 8px;
        }
      }
    }
    .rc-tree-node-content-wrapper {
      display: block;
      overflow: hidden;
      padding: 4px 4px 4px 4px;
      line-height: 1.2;
      margin: 0;
      cursor: pointer;
      text-decoration: none;
      vertical-align: top;
      .rc-tree-title{
        white-space: normal;
        overflow: hidden;
        display: block;
      }
    }
    span {
      &.rc-tree-switcher,
      &.rc-tree-checkbox,
      &.rc-tree-iconEle {
        float: left;
        line-height: 16px;
        margin-right: 4px;
        width: 16px;
        height: 16px;
        // display: inline-block;
        vertical-align: middle;
        border: 0 none;
        cursor: pointer;
        outline: none;
      }
      &.rc-tree-icon_loading {
        margin-right: 2px;
        vertical-align: top;
        background: url('data:image/gif;base64,R0lGODlhEAAQAKIGAMLY8YSx5HOm4Mjc88/g9Ofw+v///wAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFCgAGACwAAAAAEAAQAAADMGi6RbUwGjKIXCAA016PgRBElAVlG/RdLOO0X9nK61W39qvqiwz5Ls/rRqrggsdkAgAh+QQFCgAGACwCAAAABwAFAAADD2hqELAmiFBIYY4MAutdCQAh+QQFCgAGACwGAAAABwAFAAADD1hU1kaDOKMYCGAGEeYFCQAh+QQFCgAGACwKAAIABQAHAAADEFhUZjSkKdZqBQG0IELDQAIAIfkEBQoABgAsCgAGAAUABwAAAxBoVlRKgyjmlAIBqCDCzUoCACH5BAUKAAYALAYACgAHAAUAAAMPaGpFtYYMAgJgLogA610JACH5BAUKAAYALAIACgAHAAUAAAMPCAHWFiI4o1ghZZJB5i0JACH5BAUKAAYALAAABgAFAAcAAAMQCAFmIaEp1motpDQySMNFAgA7') no-repeat scroll 0 0 transparent;
      }
      &.rc-tree-switcher {
        font-size: 16px;
        &:hover{
          &:before{
            background-color: $gray-500;
            color: #fff;
            border-color: $gray-500;
          }
        }
        &:before{
          position: relative;
          z-index: 1;
          display: inline-block;
          font: 24px/1 "Material Design Icons";
          font-size: inherit;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
          content: "\F140";
          background-color: #fff;
          border: 1px solid #ddd;
          transition: all ease 0.25s;
        }
        &.rc-tree-switcher-noop {
          cursor: auto;
          &:before{
            display: none;
          }
        }
        &.rc-tree-switcher_open {
          &:before{
            content: "\F143";
          }
        }
        &.rc-tree-switcher_close {
          &:before{
            content: "\F140";
          }
        }
      }
      &.rc-tree-checkbox {
        width: 13px;
        height: 13px;
        margin: 0 3px;
        background-position: 0 0;
        &-checked {
          background-position: -14px 0;
        }
        &-indeterminate {
          background-position: -14px -28px;
        }
        &-disabled {
          background-position: 0 -56px;
        }
        &.rc-tree-checkbox-checked.rc-tree-checkbox-disabled {
          background-position: -14px -56px;
        }
        &.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled {
          position: relative;
          background: #ccc;
          border-radius: 3px;
          &::after {
            content: ' ';
            -webkit-transform: scale(1);
            transform: scale(1);
            position: absolute;
            left: 3px;
            top: 5px;
            width: 5px;
            height: 0;
            border: 2px solid #fff;
            border-top: 0;
            border-left: 0;
          }
        }
      }
    }
  }
  &:not(.rc-tree-show-line) {
    .rc-tree-switcher-noop {
      background: none;
    }
  }
  &.rc-tree-show-line {
    li:not(:last-child) {
      > ul {
        background: url('data:image/gif;base64,R0lGODlhCQACAIAAAMzMzP///yH5BAEAAAEALAAAAAAJAAIAAAIEjI9pUAA7') 0 0 repeat-y;
      }
      > .rc-tree-switcher-noop {
        background-position: -56px -18px;
      }
    }
    li:last-child {
      > .rc-tree-switcher-noop {
        background-position: -56px -36px;
      }
    }
  }
  &-child-tree {
    display: none;
    &-open {
      display: block;
    }
  }
  &-treenode-disabled {
    >span,
    >a,
    >a span {
      color: #ccc;
      cursor: not-allowed;
    }
  }
  &-node-selected {
    color: #fff;
    background-color: $gray-500;
    border: 1px $gray-500 solid;
    opacity: 0.8;
  }
  &-icon__open {
    margin-right: 2px;
    background-position: -110px -16px;
    vertical-align: top;
  }
  &-icon__close {
    margin-right: 2px;
    background-position: -110px 0;
    vertical-align: top;
  }
  &-icon__docu {
    margin-right: 2px;
    background-position: -110px -32px;
    vertical-align: top;
  }
}

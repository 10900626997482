.elFixWidth {
  height: 0 !important;
  overflow: hidden !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.menuFixar {
  z-index: 100;
}

.menuClear {
  visibility: hidden;
  opacity: 0;
}

.menuFixar.fixDisable {
  position: static !important;
  width: auto !important;
}

.js-fix {
  &.menuFixar {
    background-color: #fff;
    padding: 0 0 10px;
    margin-top: -15px;
    padding-top: 15px;
  }
}

.modal-alert{
  position: relative;
  &.modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    @media(max-width: 768px) {
      min-height: calc(100vh - 20px);
    }
  }
  &.modal-danger{
    .modal-content{
      background-color: $danger;
      color: #fff;
      border: 0;
    }
  }
  &.modal-success{
    .modal-content{
      background-color: $success;
      color: #fff;
      border: 0;
    }
  }
  &.modal-warning{
    .modal-content{
      background-color: $warning;
      color: #fff;
      border: 0;
    }
  }
  &.modal-info{
    .modal-content{
      background-color: $info;
      color: #fff;
      border: 0;
    }
  }
  .close{
    float: none;
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 2;
    opacity: 0.7;
    text-shadow: none;
  }
  .modal-content{
    padding: 24px;
    font-size: 14px;
    line-height: 1.3;
  }
}

.car-list{
  &__item{
    position: relative;
    padding: 12px 16px 12px 56px;
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    min-height: 65px;
    @media(max-width: 400px){
      display: block;
      margin-right: 0;
    }
    &.car{
      .car__number{
        border: 0;
        min-width: inherit;
        padding: 0;
      }
      .mdi{
        left: 16px;
        top: 10px;
        font-size: 24px;
      }
    }
  }
}

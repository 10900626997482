.recovery{
  background-color: #fff;
  height: 100%;
  padding: 65px 60px 50px;
  &__inner{
    height: 100%;
  }
  &__title{
    margin-bottom: 65px;
  }
  &__form  .form-group:first-of-type{
    margin-bottom: 65px;
  }
}

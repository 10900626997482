// component form
.form-additional{
  padding: 4px 0;
  a{
    display: inline-block;
    margin-right: 15px;
  }
}
.input-group-btn{
  .form-control{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -1px;
  }
}
.form-control__wrapper{
  position: relative;
  .spinner{
    position: absolute;
    right: 6px;
    top: 8px;
    font-size: 2px;
  }
  .input-close{
    top: 8px;
    position: absolute;
    right: 6px;
    + .spinner{
      right: 30px;
    }
  }
}
.input-close{
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  &:hover{
    color: $danger;
  }
}
.label-group{
  label{
    width: 50%;
    padding-right: 20px;
  }
}
// component file
.c-file {
  position: relative;
  text-align: center;

  .btn {
    float: left;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .c-file__wrapper {
    overflow: hidden;

    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .c-file__path {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;

    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
}

.is-open{
  .form-result{
    display: block;
  }
}
.form-result{
    position: absolute;
    display: none;
    z-index: 2;
    left: 0;
    right: 0;
    padding-top: 6px;
    ul{
        margin: 0;
        background-color: #fff;
        padding: 6px 10px;
        box-shadow: 0 0 5px rgba(0,0,0,.2);
        border-radius: 4px;
        border: 1px solid $input-border-color;
        list-style: none;
        li{
            font-size: 13px;
            margin-bottom: 8px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}


.input-group-btn {
  margin-left: -1px;
}
.form-control+.input-group-addon:not(:first-child) {
  border-left: 0;
}
.input-group > .input-group-btn > .btn,
.input-group-addon:not(:first-child){
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-addon {
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.news-detail{
  &__date{
    display: block;
    color: #888;
    margin-bottom: 10px;
  }
  &__picture{
    margin-bottom: 20px;
  }
}

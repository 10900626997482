.loader,
.loader:after,
.loader:before {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: $danger;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:after,
.loader:before {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -2.5em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 2.5em;
}
@-webkit-keyframes load7 {
  0%,
  100%,
  80% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  100%,
  80% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.spinner {
  margin: 0 auto;
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1em solid rgba($primary, 0.2);
  border-right: 1em solid rgba($primary, 0.2);
  border-bottom: 1em solid rgba($primary, 0.2);
  border-left: 1em solid $primary;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.panel{
  padding: 10px 0;
  @include clearfix();
  .btn.float-right{
    margin-right: 0;
  }
  .btn{
    margin-right: 10px;
    margin-bottom: 10px;
    @media(max-width: 450px){
      width: 100%;
      margin-right: 0;
    }
  }
}

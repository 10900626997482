body,
html {
  height: 100%;
}
.driver-map {
  height: 400px;
}
body.is-fixed {
  position: fixed;
  overflow: hidden;
}

body {
  &.is-hidden {
    overflow: hidden;
  }
}

.alert-delivery {
  font-size: 18px;
  .mdi {
    font-size: 24px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

.modal-xlg {
  @media (min-width: 992px) {
    max-width: 1000px;
  }
  @media (min-width: 576px) {
    max-width: 500px;
  }
}

.overflow-h {
  overflow: hidden;
}
.is-hide {
  display: none;
}
.text-vertical {
  width: 32px;
  height: 200px;
  display: inline-block;
  position: relative;
  text-align: left;
  &__inner {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
  }
}
.pagination {
  margin-top: 16px;
}
.list-unstyled {
  .mdi {
    font-size: 18px;
    vertical-align: middle;
  }
}
.small,
small {
  line-height: 1.2;
}

.logo {
  width: 120px;
  display: inline-block;
}

.form-actions {
  .btn {
    @media (max-width: 380px) {
      width: 100%;
      margin-right: 0;
    }
  }
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

img {
  max-width: 100%;
}

.phone {
  white-space: nowrap;
  color: $primary;
  font-weight: 600;
}

.mh-460 {
  max-height: 460px;
}
.mh-340 {
  max-height: 340px;
}

button {
  cursor: pointer;
}

.text-line-through {
  text-decoration: line-through;
}

.img-rounded {
  border-radius: 4px;
}

.display-5 {
  font-size: 3rem;
}

.display-6 {
  font-size: 36px;
}

.box {
  border-radius: 4px;
  padding: 10px;
}

html body .dl {
  display: inline-block;
}

html body .db {
  display: block;
}

.no-wrap td,
.no-wrap th {
  white-space: nowrap;
}
//Blockquote

html body blockquote {
  border-left: 5px solid #398bf7;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 15px;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}
//Paddings

html body .p-0 {
  padding: 0;
}

html body .p-10 {
  padding: 10px;
}

html body .p-20 {
  padding: 20px;
}

html body .p-30 {
  padding: 30px;
}

html body .p-l-0 {
  padding-left: 0;
}

html body .p-l-10 {
  padding-left: 10px;
}

html body .p-l-20 {
  padding-left: 20px;
}

html body .p-r-0 {
  padding-right: 0;
}

html body .p-r-10 {
  padding-right: 10px;
}

html body .p-r-20 {
  padding-right: 20px;
}

html body .p-r-30 {
  padding-right: 30px;
}

html body .p-r-40 {
  padding-right: 40px;
}

html body .p-t-0 {
  padding-top: 0;
}

html body .p-t-10 {
  padding-top: 10px;
}

html body .p-t-20 {
  padding-top: 20px;
}

html body .p-t-30 {
  padding-top: 30px;
}

html body .p-b-0 {
  padding-bottom: 0;
}

html body .p-b-5 {
  padding-bottom: 5px;
}

html body .p-b-10 {
  padding-bottom: 10px;
}

html body .p-b-20 {
  padding-bottom: 20px;
}

html body .p-b-30 {
  padding-bottom: 30px;
}

html body .p-b-40 {
  padding-bottom: 40px;
}
//Margin

html body .m-0 {
  margin: 0;
}

html body .m-l-5 {
  margin-left: 5px;
}

html body .m-l-10 {
  margin-left: 10px;
}

html body .m-l-15 {
  margin-left: 15px;
}

html body .m-l-20 {
  margin-left: 20px;
}

html body .m-l-30 {
  margin-left: 30px;
}

html body .m-l-40 {
  margin-left: 40px;
}

html body .m-r-5 {
  margin-right: 5px;
}

html body .m-r-10 {
  margin-right: 10px;
}

html body .m-r-15 {
  margin-right: 15px;
}

html body .m-r-20 {
  margin-right: 20px;
}

html body .m-r-30 {
  margin-right: 30px;
}

html body .m-r-40 {
  margin-right: 40px;
}

html body .m-t-0 {
  margin-top: 0;
}

html body .m-t-5 {
  margin-top: 5px;
}

html body .m-t-10 {
  margin-top: 10px;
}

html body .m-t-15 {
  margin-top: 15px;
}

html body .m-t-20 {
  margin-top: 20px;
}

html body .m-t-30 {
  margin-top: 30px;
}

html body .m-t-40 {
  margin-top: 40px;
}

html body .m-b-0 {
  margin-bottom: 0;
}

html body .m-b-5 {
  margin-bottom: 5px;
}

html body .m-b-10 {
  margin-bottom: 10px;
}

html body .m-b-15 {
  margin-bottom: 15px;
}

html body .m-b-20 {
  margin-bottom: 20px;
}

html body .m-b-30 {
  margin-bottom: 30px;
}

html body .m-b-40 {
  margin-bottom: 40px;
}

html body .m-b-100 {
  margin-bottom: 100px;
}

html body .vt {
  vertical-align: top;
}

html body .vm {
  vertical-align: middle;
}

html body .vb {
  vertical-align: bottom;
}
// opacity

.op-5 {
  opacity: 0.5;
}

.op-3 {
  opacity: 0.3;
}
// fonts

html body .font-bold {
  font-weight: 700;
}

html body .font-normal {
  font-weight: normal;
}

html body .font-light {
  font-weight: 300;
}

html body .font-medium {
  font-weight: 600;
}

html body .font-16 {
  font-size: 16px;
}

html body .font-14 {
  font-size: 14px;
}

html body .font-10 {
  font-size: 10px;
}

html body .font-18 {
  font-size: 18px;
}

html body .font-20 {
  font-size: 20px;
}
html body .font-30 {
  font-size: 30px;
}

html body .bg-light-part {
  background-color: rgba(0, 0, 0, 0.02);
}

html body .bg-light-primary {
  background-color: #f1effd;
}

html body .bg-light-success {
  background-color: #e8fdeb;
}

html body .bg-light-info {
  background-color: lighten($primary, 40%);
}

html body .bg-light-extra {
  background-color: #ebf3f5;
}

html body .bg-light-warning {
  background-color: #fff8ec;
}

html body .bg-light-danger {
  background-color: #f9e7eb;
}

html body .bg-light-inverse {
  background-color: #f6f6f6;
}

html body .bg-light {
  background-color: #e9edf2;
}

html body .bg-white {
  background-color: #ffffff;
}

html body .b-0 {
  border: none !important;
}
html body .b-b {
  border-bottom: 1px solid #ced4da;
}
html body .b-t {
  border-top: 1px solid #ced4da;
}
html body .b-l {
  border-left: 1px solid #ced4da;
}

@media (min-width: 576px) {
  .modal-xl {
    max-width: $modal-xl;
  }

  .modal-full {
    max-width: $modal-full;
  }
}

.is-sticky {
  position: sticky;
  top: 88px;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}


.notification-nav{
  margin-bottom: 1rem;
  overflow: auto;
}

.notifications{
  margin-bottom: 2rem;
  @include media-breakpoint-down(xs){
    margin-bottom: 1rem;
  }
}

.notification-group{
  padding: 0.5rem 1rem;
  border-radius: 0;
  background-color: #f2f2f2;
  margin-top: -2px;
  z-index: 1;
  position: relative;
  time{
    color: #333;
    font-weight: 500;
    font-size: 14px;
  }
}

.notifications-item{
  display: flex;
  &__left{
    padding: 1.5rem 0;
    width: 48px;
    @include media-breakpoint-down(xs){
      padding: 1rem 0;
    }
  }
  &__body{
    padding: 1.5rem 0;
    flex: 1;
    align-self: center;
    display: flex;
    border-bottom: 1px solid $gray-200;
    @include media-breakpoint-down(xs){
      flex-direction: column;
      padding: 1rem 0;
    }
  }
}

.notification{
  &.is-success{
    .notification__icon{
      fill: $success;
    }
    .notification__button{
      .btn{
        color: $success;
        border-color: $success;
        &:hover{
          color: $white;
          background-color: $success;
        }
      }
    }
  }
  &.is-danger{
    .notification__icon{
      fill: $danger;
    }
    .notification__button{
      .btn{
        color: $danger;
        border-color: $danger;
        &:hover{
          color: $white;
          background-color: $danger;
        }
      }
    }
  }
  &.is-warning{
    .notification__icon{
      fill: $warning;
    }
    .notification__button{
      .btn{
        color: $warning;
        border-color: $warning;
        &:hover{
          color: $white;
          background-color: $warning;
        }
      }
    }
  }
  &.is-unread{
    .notification__title{
      font-weight: 700;
      color: $body-color;
    }
    .notification__date{
      font-weight: 700;
      color: $body-color;
    }
  }
  &__content{
    flex: 1;
    padding-right: 2rem;
    @include media-breakpoint-down(xs){
      padding-right: 0;
      margin-bottom: 6px;
      width: 100%;
    }
    a{
      text-decoration: underline;
    }
  }
  &__icon{
    font-size: 26px;
    line-height: 0;
    fill: $gray-500;
  }
  &__title{
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 1.3;
  }
  &__message{
    font-size: 14px;
    color: #888;
  }
  &__date{
    color: #999;
    font-size: 14px;
  }
  &__button{
    padding-right: 2rem;
  }
}
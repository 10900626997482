.filter-more {
  position: relative;
  height: 32px;
  padding: 0 36px 0 14px !important;
  background-color: $gray-200;
  border-radius: 20px;
  color: $body-color;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  transition: box-shadow 0.25s ease, background-color 0.25s ease;
  border: 0;
  outline: none;

  &:focus {
    outline: none;
  }

  &::before {
    position: absolute;
    top: 50%;
    right: 12px;
    width: 15px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4v2z'/%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 15px 14px;
    content: "";
    transform: translateY(-50%);
  }

  &__title {
  }

  &__count {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: -3px;
    margin-left: 4px;
    background-color: $danger;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
  }
}

.filter-footer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__results {
    @media (max-width: 500px) {
      margin-bottom: 6px;
    }
  }

  &__legend {
    margin-left: auto;
  }
}

.filter-list {
  // background-color: $gray-100;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;

  .filter-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    flex-wrap: wrap;

    @media (min-width: 961px) {
      display: flex !important;
    }

    &__label {
      margin-right: 0.5rem;
    }
    &__value {
    }
    .dropdown-toggle {
      &:after {
        margin-left: 0.45em;
        vertical-align: 0.18em;
      }
    }
    .dropdown-menu {
    }
    .scrollbar-macosx {
      max-height: 210px;
      overflow-y: auto;
    }
    .btn-link {
      background-color: $gray-200;
      border-radius: 20px;
      padding: 4px 14px 5px;
      color: $body-color;
      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
    .show {
      .btn-link {
        background-color: $primary;
        color: #fff;
      }
    }

    .checkbox {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

.filter-clean {
  height: 32px;
  padding: 0;
  color: $primary;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  transition: color ease 0.25s;
  background-color: transparent;
  border: 0;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $primary;
  }
}

.search {
  position: relative;
  width: 100%;
  .btn {
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 24px;
    padding: 0;
    line-height: 1;
    transform: translateY(-50%);
    color: $gray-500;
    box-shadow: none;

    &:focus,
    &:active:focus,
    &:hover {
      color: $body-color;
      box-shadow: none;
    }
  }
  .form-control {
    padding-right: 40px;
    min-width: 320px;
    border-radius: 20px;
    @include media-breakpoint-down(xs) {
      min-width: auto;
    }
  }
}

.filter-selected {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;

  .chip {
    margin-right: 6px;
    margin-bottom: 6px;
  }
}

// CHIP

.chip-list {
  display: flex;
  align-items: center;
  margin-right: 8px;
  flex-wrap: wrap;

  &__label {
    color: $gray-700;
    margin-right: 6px;
    margin-bottom: 6px;
    height: 26px;
    line-height: 24px;
    font-size: 13px;
  }
}

.chip {
  height: 26px;
  padding: 0 8px;
  background-color: #f3f3f3;
  color: $body-color;
  cursor: pointer;
  font-size: 12px;
  font-weight: font-weight(semibold);
  line-height: 26px;
  border-radius: 20px;
  white-space: nowrap;

  &__close {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border: 1px solid transparent;
    border-radius: 50%;
    transition: border-color 0.25s ease;
    line-height: 18px;
  }

  &:hover {
    .chip__close {
      border: 1px solid $input-border-color;
    }
  }
}

// SIDEBAR
$sidebar-padding: 24px;

.filters-sidebar {
  display: none;

  &.is-open {
    display: block;
  }

  &[aria-hidden="false"] {
    .filters-sidebar__overlay {
      animation-name: fadeIn;
    }

    .filters-sidebar__container {
      animation-name: slideInRight;
    }
  }

  &[aria-hidden="true"] {
    .filters-sidebar__overlay {
      animation-name: fadeOut;
    }

    .filters-sidebar__container {
      animation-name: slideOutRight;
    }
  }

  &.is-loading {
    .filters-sidebar__container {
      &::after {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #ffffff;
        content: "";
      }
    }
  }

  &__overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    background: rgba($black, 0.3);
    will-change: opacity;
  }

  &__container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 0;
    margin: 0;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    background: $white;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
    will-change: transform;

    @include media-breakpoint-up(sm) {
      width: 380px;
    }
  }

  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px $sidebar-padding;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
  }

  &__title {
    flex: 1;
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: (24/16);
  }

  &__close {
    width: 24px;
    height: 24px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    margin-top: -12px;
    right: $sidebar-padding;
    margin-left: auto;
    border-radius: 50%;
    color: $gray-600;
    font-size: 20px;
    line-height: 0;
    transition: color ease 0.25s;
    border: 0;
    background-color: transparent;
    &:focus,
    &:hover {
      color: $body-color;
      outline: none;
    }
  }

  &__body {
    flex: 1;
    padding: 0;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: $gray-400;
    }
  }

  &__footer {
    display: flex;
    height: 56px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    justify-items: center;
    white-space: nowrap;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);

    .btn-outline-primary {
      &:hover {
        color: $white;
      }
    }

    .btn {
      flex: 1;

      &:first-child {
        margin-right: 12px;
      }
    }
  }
}

.filter-box {
  position: relative;

  &:last-child {
    &::after {
      display: none;
    }
  }

  &::after {
    position: absolute;
    right: $sidebar-padding;
    bottom: 0;
    left: $sidebar-padding;
    height: 1px;
    background-color: $gray-200;
    content: "";
  }

  &.is-close {
    .dropdown-box {
      display: none;
    }
  }

  &__heading {
    position: relative;
    padding-right: $sidebar-padding;
    padding-left: $sidebar-padding;
  }

  &__btn {
    position: relative;
    display: flex;
    width: 100%;
    height: 44px;
    flex-direction: row;
    align-items: center;
    padding: 0 0 0 24px;
    border-radius: 4px;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    outline: none;

    &:focus {
      outline: none;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 10'%3E%3Cpath stroke='#888' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15 1.5l-7 7-7-7'/%3E%3C/svg%3E");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 14px 7px;
      content: "";
      transform-origin: center;
      transition: transform ease 0.25s;
    }

    &[aria-expanded="false"] {
      &::before {
        transform: rotate(-90deg);
      }
    }

    &:focus:hover {
      box-shadow: none;
    }

    &:focus {
      .filter-box__title {
        color: rgba($body-color, 0.8);
      }
    }
  }

  &__title {
    display: inline-block;
    overflow: hidden;
    border-bottom: 1px dashed transparent;
    margin-top: -1px;
    margin-right: 16px;
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: (18/14);
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__controls {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;
    display: none;
    flex-direction: row;
    align-items: center;
    padding-right: $sidebar-padding;
    transform: translateY(-50%);

    &.is-show {
      display: flex;
    }

    &.is-show ~ .filter-box__btn {
      padding-right: 46px;
    }
  }

  &__count {
    width: 20px;
    height: 20px;
    background-color: $danger;
    border-radius: 50%;
    color: $white;
    font-size: 10px;
    font-weight: $font-weight-bold;
    line-height: 20px;
    text-align: center;
  }

  &__clean {
    width: 20px;
    height: 20px;
    padding: 0;
    border: 1px solid $white;
    margin-left: 6px;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 8 8'%3E%3Cpath fill='#888' d='M7.18.82a1 1 0 00-1.41 0L4 2.59 2.23.82A1 1 0 00.82 2.23L2.59 4 .82 5.77a1 1 0 001.41 1.41L4 5.41l1.77 1.77a1 1 0 001.41-1.41L5.41 4l1.77-1.77a1 1 0 000-1.41z'/%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 8px 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: border-color ease 0.25s;

    &:focus {
      box-shadow: 0 0 0 2px rgba($primary, 0.2);
    }

    &:hover {
      border-color: $gray-400;
    }
  }

  &__more {
    color: $primary;
    cursor: pointer;
    font-size: 14px;
  }

  .dropdown-box {
    overflow: inherit;
    max-height: inherit;
    padding-bottom: 16px;
    transition: all ease 0.25s;
  }

  .dropdown-box__more {
    position: relative;
    padding-right: $sidebar-padding;
    padding-left: $sidebar-padding;

    &::before {
      position: absolute;
      right: 0;
      bottom: 100%;
      left: 0;
      height: 8px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      content: "";
    }
  }

  .dropdown-list {
    padding-left: $sidebar-padding;
    padding-right: $sidebar-padding;

    overflow-y: auto;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: $gray-500;
    }

    .custom-control {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  .dropdown-box__search {
    padding-bottom: 8px;

    .dropdown-search {
      margin-right: $sidebar-padding;
      margin-left: $sidebar-padding;
      position: relative;
      margin-bottom: 0;

      &__input {
        width: 100%;
        height: $input-height;
        padding: 0 12px;
        border: 1px solid $gray-400;
        border-radius: 4px;
        font-size: 14px;
        transition: border-color ease 0.25s, box-shadow ease 0.25s;

        &:focus {
          border-color: $primary;
          box-shadow: 0 0 0 1px $primary;
          outline: none;
        }
      }

      &__icon {
        position: absolute;
        top: 50%;
        right: 12px;
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        padding: 0;
        line-height: 0;
        transform: translateY(-50%);
        border: 0;
        background-color: transparent;
        outline: none;

        .mdi {
          color: $gray-500;
          font-size: 20px;
          transition: color ease 0.25s;
        }
      }

      .btn-clear {
        border-radius: 10px;
        background-color: $gray-200;

        &:hover {
          .mdi {
            color: $black;
          }
        }

        .mdi {
          font-size: 12px;
          color: $gray-700;
        }
      }
    }
  }

  .dropdown-menu {
    max-height: 448px; // 13 items
    padding-right: $sidebar-padding;
    padding-bottom: 6px;
    padding-left: $sidebar-padding;
    margin-right: 24px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
    visibility: hidden;
  }
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

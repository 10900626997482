// When click on sidebar toggler and also for tablet

@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav li {
    position: relative;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li ul {
    position: absolute;
    left: 70px;
    top: 46px;
    width: 220px;
    padding-bottom: 10px;
    z-index: 1001;
    background: #edf0f5;
    display: none;
    padding-left: 1px;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li:last-child{
    ul {
      top: auto;
      bottom: 100%;
      padding-top: 10px;
      padding-bottom: 0;
    }
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li ul.flow-up {
    top: auto;
    bottom: 100%;
    padding-top: 10px;
    padding-bottom: 0;
  }

  .mini-sidebar .user-profile .profile-img {
    width: 45px;
  }

  .mini-sidebar .user-profile .profile-img .setpos {
    top: -35px;
  }

  .mini-sidebar.fix-sidebar .left-sidebar {
    position: fixed;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover ul {
    height: auto !important;
    overflow: auto;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover ul,
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover ul.collapse {
    display: block;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li > a.has-arrow:after {
    display: none;
  }

  .mini-sidebar .left-sidebar {
    width: 70px;
  }

  .mini-sidebar .sidebar-nav #sidebarnav .user-profile > a {
    padding: 12px 20px;
  }

  .mini-sidebar .scroll-sidebar {
    padding-bottom: 0;
    position: absolute;
  }

  .mini-sidebar .hide-menu,
  .mini-sidebar .nav-small-cap,
  .mini-sidebar .sidebar-footer,
  .mini-sidebar .user-profile .profile-text,
  .mini-sidebar > .label {
    display: none;
  }

  .mini-sidebar .nav-devider {
    width: 70px;
  }

  .mini-sidebar .sidebar-nav {
    background: transparent;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li > a {
    padding: 12px 24px;
    width: 70px;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
    width: 290px;
    background: #edf0f5;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .hide-menu {
    display: inline;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .label {
    display: none;
  }
}
@media (max-width: 767px) {
  .mini-sidebar .left-sidebar {
    position: fixed;
  }

  .mini-sidebar .left-sidebar,
  .mini-sidebar .sidebar-footer {
    left: -240px;
  }

  .mini-sidebar.show-sidebar .left-sidebar,
  .mini-sidebar.show-sidebar .sidebar-footer {
    left: 0;
  }
}

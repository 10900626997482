body {
  overflow-x: hidden;
}
// #main-wrapper

.page {
  position: relative;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  @include clearfix();
}
.page-title{
  margin-bottom: 1rem;
  h1{
    font-size: 24px;
    @include media-breakpoint-down(xs) {
      font-size: 18px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
    @include media-breakpoint-down(md) {
      font-size: 26px;
    }
  }
}
.page-bg{
  background-color: #f2f2f2;
}
.page-center{
  display: flex;
  align-items: center;
  position: relative;
  min-height: 100vh;
  height: 100vh;
}
.boxed{
  .page {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
    .sidebar-footer {
      position: absolute;
    }
    .topbar {
      max-width: 1300px;
    }
    .footer {
      display: none;
    }
  }
}
.pagination{
  .page-link{
    .mdi{
      display: inline-block;
      line-height: 0;
    }
  }
}
.catalog-price {
  flex: 1;
  color: #fff;
  @media (max-width: 360px) {
    padding-right: 1rem;
  }
  .list-unstyled {
    margin-bottom: 0;
  }
  em {
    display: none;
    font-style: normal;
    @include media-breakpoint-up(sm) {
      display: inline-block;
    }
  }
  li {
    .rouble {
      font-weight: normal;
    }
    .price {
      font-size: 18px;
    }
  }
}

.catalog-detail {
  margin-bottom: 24px;
  display: flex;
  @supports (display: grid) {
    align-items: start;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: max-content;
    grid-template-areas:
      "slider body"
      "slider body";
  }
  @include media-breakpoint-down(xl) {
    flex-direction: column;
    @supports (display: grid) {
      grid-template-columns: 100%;
      grid-template-areas:
        "slider"
        "body";
    }
  }

  .nav.nav-tabs {
    @include media-breakpoint-down(xs) {
      display: block;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      .nav-item {
        display: inline-block;
      }
    }
  }

  .table-quantity {
    margin-bottom: 2rem;
    @include media-breakpoint-up(sm) {
      width: auto;
      border-bottom: 1px solid $border-color;
    }
    thead {
      th {
        background-color: #fff;
      }
    }
    tbody {
      em {
        display: inline-block;
        font-style: normal;
        font-weight: 700;
        @include media-breakpoint-up(sm) {
          display: none;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      thead {
        display: none;
      }
      tr {
        display: block;
        background-color: #f9f9f9;
        border-radius: 8px;
        margin-bottom: 1rem;
        padding: 1rem;
        td {
          display: block;
          border-top: 0;
          text-align: left !important;
          padding: 0;
          &:first-child {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $border-color;
            b {
              font-size: 16px;
            }
          }
        }
      }
      .quantity {
        margin-top: 1rem;
      }
    }
  }

  &__slider {
    flex: 1 1 50%;
    width: 50%;
    @include media-breakpoint-down(xl) {
      flex-basis: auto;
      width: 100%;
      margin-bottom: 1rem;
    }
    @supports (display: grid) {
      width: auto !important;
      grid-area: slider;
    }
  }
  &__body {
    flex: 1 1 50%;
    width: 50%;
    padding-left: 64px;
    @include media-breakpoint-down(xl) {
      flex-basis: auto;
      width: 100%;
      padding-left: 0;
    }
    @supports (display: grid) {
      grid-area: body;
      width: auto !important;
    }
  }
  &__title {
    // width: 50%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }
  }
  &__artikul {
    margin-top: 1rem;
    margin-bottom: -0.5rem;
  }
  &__price {
    // flex: 1;
    // padding-right: 2rem;
    // color: #fff;
    margin-bottom: 1rem;
    li{
      span{
        color: $gray-600;
        display: inline-block;
        margin-right: 8px;
      } 
      b{
        span{
          color: $body-color;
        }
      }
      .price{
        color: $danger;
        font-size: 18px;
        span{
          color: $danger;
        }
      }
    }
  }
  &__buy {
    display: inline-flex;
    align-items: center;
    background-color: $primary;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    margin-left: 50px;
    margin-top: 30px;
    height: 55px;
    @include media-breakpoint-down(xs) {
      border-radius: 16px 16px 0 0;
      margin-bottom: 1rem;
      position: fixed;
      bottom: 0;
      display: flex;
      z-index: 100;
      align-items: center;
      left: 0;
      right: 0;
      margin: 0;
      padding: 8px 16px;
      box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.07), 0 -2px 2px rgba(0, 0, 0, 0.07), 0 -4px 4px rgba(0, 0, 0, 0.07),
        0 -8px 8px rgba(0, 0, 0, 0.07);
    }
  }
  &__stock {
    margin-bottom: 1rem;
    // background-color: $gray-100;
    // padding: 1rem;
    // border-radius: 8px;
    // display: inline-block;
    > div {
      margin-bottom: 0.25rem;
    }
    em {
      font-style: normal;
      color: $gray-600;
    }
  }
  &__description {
    font-size: 14px;
    margin-bottom: 1rem;
  }
}

.table-wrapper {
  display: flex;

  button {
    margin-left: 50px;
    margin-top: 35px;
    height: 50px;
    padding: 0 20px;
  }
}

.params {
  margin: 0 0 1.5rem;
  padding: 0;
  list-style: none;
  width: 100%;
  display: block;
  &__item {
    display: flex;
    margin-bottom: 8px;
  }
  &__label {
    color: $gray-600;
    width: 50%;
    position: relative;
    span {
      position: relative;
      background-color: #fff;
      padding-right: 4px;
    }
    &:before {
      content: "";
      display: block;
      left: 0;
      top: 18px;
      position: absolute;
      width: 100%;
      border-bottom: 1px dotted $border-color;
    }
  }
  &__value {
    width: 50%;
    font-weight: 600;
    padding: 0 16px 0 4px;
    span {
      position: relative;
      background-color: #fff;
    }
  }
}

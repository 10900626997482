.files{
  &__group-title{
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    margin: 30px 0 8px;
    padding: 0;
    color: #888;
  }
}
.modal-body{
  .files-item{
    width: calc( 100% / 4 - 20px);
    @include media-breakpoint-down(xl){
      width: calc( 100% / 4 - 20px);
    }
    @include media-breakpoint-down(lg){
      width: calc( 100% / 4 - 20px);
    }
    @include media-breakpoint-down(md){
      width: calc( 100% / 3 - 20px);
    }
    @include media-breakpoint-down(sm){
      width: calc( 100% / 3 - 20px);
    }
    @include media-breakpoint-down(xs){
      width: calc( 100% / 2 - 20px);
    }
    @media(max-width: 360px){
      width: 100%;
    }
  }
}
.files-item{
  border: 1px solid $gray-300;
  overflow: hidden;
  border-radius: 4px;
  display: inline-block;
  margin-top: 1rem;
  margin-right: 1rem;
  width: calc( 100% / 7 - 20px);
  @include media-breakpoint-down(xl){
    width: calc( 100% / 6 - 20px);
  }
  @include media-breakpoint-down(lg){
    width: calc( 100% / 5 - 20px);
  }
  @include media-breakpoint-down(md){
    width: calc( 100% / 4 - 20px);
  }
  @include media-breakpoint-down(sm){
    width: calc( 100% / 3 - 20px);
  }
  @include media-breakpoint-down(xs){
    width: calc( 100% / 2 - 20px);
  }
  @media(max-width: 360px){
    width: 100%;
  }
  a{
    color: $body-color;
    &:hover{
      text-decoration: none;
      color: $link-color;
      .file-ext{
        span{
          background-color: $primary;
        }
      }
    }
  }
  &__icon{
    padding-top: 75%;
    position: relative;
    overflow: hidden;
    text-align: center;
  }
  &__title{
    padding: 12px 12px;
  }
}

.file-title{
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; 
}
.file-img{
  position: absolute;
  left: 0;
  top: 0;
  object-fit: fill;
  width: 100%;
  height: 100%;
}
.file-ext{
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  span{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: $secondary;
    padding-left: 0.5em;
    padding-right: 0.5em;
    height: 2em;
    font-size: 2em;
    font-weight: bold;
    border-radius: 4px;
    transition: all ease 0.25s;
    @include media-breakpoint-down(sm){
      font-size: 1.5em;
    }
  }
}
.file-delete{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1rem;
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  background-color: #ccc;
  color: $body-color;
  z-index: 1;
  transition: all ease 0.25s;
  &:hover{
    background-color: $primary;
    color: #fff;
  }
}
.picture-grid {
  position: relative;
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.picture-drop {
  background-color: $gray-100;
  padding: 16px;
  border-radius: $border-radius;
  border: 1px dashed $border-color;
  font-size: 14px;
  color: $gray-700;
  text-align: center;
  width: 100%;
  margin: 0 15px;
  margin-bottom: 16px;
  cursor: default;
}

.picture-cell {
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  @include media-breakpoint-down(xl) {
    width: 33.33%;
  }

  @include media-breakpoint-down(sm) {
    width: 50%;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
  }

  .item {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    margin-bottom: 24px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: 10px;

    &:hover {
      .item__delete {
        opacity: 1;
      }
    }

    &__picture {
      position: relative;
      z-index: 1;
      overflow: hidden;
      padding-top: 100%;
      background-color: #fff;
      border-radius: 3px;
      text-align: center;

      img {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    &__tags {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &__toolbar {
      margin-top: 16px;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      margin-top: 8px;
      font-size: 14px;
    }

    &__delete {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 24px;
      color: $body-color;
      z-index: 3;
      cursor: pointer;
      line-height: 0;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity ease 0.25s;

      &:hover {
        color: $danger;
      }

      @include media-breakpoint-down(sm) {
        opacity: 1;
      }
    }
  }
}

.rc-tree-pm {
  display: inline-flex;
  padding: 4px;
}
.rc-tree-plus {
  margin-right: 4px;
  background-color: $success;
}
.rc-tree-minus {
  margin-right: 4px;
  background-color: $danger;
}

.rc-tree-plus,
.rc-tree-minus{
  color: $white;
  font-weight: bold;
  font-size: 14px;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
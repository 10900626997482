.table{
  .list-file{
    li{
      margin-bottom: 0;
    }
  }
}

.legend-list{
  margin: 0 0 6px;
  padding: 0;
  list-style: none;
  li{
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
  }
}

.list-file {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 10px;
    }

    a {
      padding: 4px 0;
      position: relative;
      padding-left: 34px;
      min-height: 30px;
      display: inline-block;
      color: #1e2029;
      // &:hover{
      //   &:before{
      //     color: $body-color;
      //   }
      // }
      span {
        border-bottom: 1px solid;
      }

      // &[href$=".doc"],
      // &[href$=".docx"],
      // &[href$=".rtf"] {
      //   &:before {
      //     content: '\F22C';
      //     // background-image: url("../images/file-word.png");
      //   }
      // }

      // &[href$='.pdf'] {
      //   &:before {
      //     content: '\F225';
      //     // background-image: url("../images/file-acrobat.png");
      //   }
      // }

      // &[href$='.7z'],
      // &[href$='.rar'],
      // &[href$='.zip'] {
      //   &:before {
      //     content: '\F217';
      //     // background-image: url("../images/file-archive.png");
      //   }
      // }

      // &[href$=".jpeg"],
      // &[href$=".jpg"],
      // &[href$=".png"],
      // &[href$=".tif"],
      // &[href$=".tiff"],
      // &[href$='.gif'] {
      //   &:before {
      //     content: '\F21F';
      //     // background-image: url("../images/file-picture.png");
      //   }
      // }

      // &[href$=".xls"],
      // &[href$=".xlsb"],
      // &[href$=".xlsm"],
      // &[href$=".xlsx"] {
      //   &:before {
      //     content: '\F21B';
      //     // background-image: url("../images/file-excel.png");
      //   }
      // }

      // &:before {
      //   font: 24px/1 "Material Design Icons";
      //   content: "\F219";
      //   color: #888;
      //   // background-image: url("../images/file-text.png");
      //   width: 30px;
      //   height: 30px;
      //   line-height: 28px;
      //   text-align: center;
      //   position: absolute;
      //   transition: color ease 0.25s;
      //   left: 0;
      //   top: -1px;
      // }
    }
  }
}
.files-list-grid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  @media (max-width: 1600px){
    grid-template-columns: repeat(3,1fr);
  }
  @media (max-width: 1440px){
    grid-template-columns: repeat(2,1fr);
  }
  @media (max-width: 992px){
    grid-template-columns: 1fr;

  }
}


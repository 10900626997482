.view-stats{
  margin-bottom: 30px;
  .card{
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: -1px;
    &:nth-child(even){

      .card-header{
        // background-color: #f9f9f9;
      }
    }
  }
  .card-header{
    padding: .5rem 0.875rem;
    display: flex;
    align-items: center;
    background-color: #fff;
    .view-stats__client{
      display: inline;
      padding-left: 10px;
      @include media-breakpoint-down(sm){
        display: none;
      };
    }
    .btn{
      min-width: 40px;
      &.collapsed{
        .mdi{
          &:before{
            content: "\F140";
          }
        }
      }
    }
  }
  .card-body{
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    background-color: #f4f4f4;
  }
  &__header{
    flex: 1 1 auto;
  }
  &__state{
    margin-left: auto;
    padding: 0 10px;
    text-align: right;
    .mdi{
      font-size: 24px;
      line-height: 1;
    }
  }
  &__list{
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    list-style: none;
    li{
      display: inline-block;
      margin-right: 30px;
    }
  }
  &__number{
    font-size: 18px;
    font-weight: 700;
    color: theme-color('primary');
    @include media-breakpoint-down(xs){
      font-size: 16px;
    };
  }
  &__note{
    padding: 0 0 0 10px;
    font-size: 22px;
  }
  &__date{
    color: #888;
    font-size: 12px;
    line-height: 1.2;
    span{
      white-space: nowrap;
    }
  }
}

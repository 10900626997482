.label {
  padding: 3px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 12px;
}

.label-rounded {
  border-radius: 60px;
}

.label-custom {
  background-color: #56c0d8;
}

.label-success {
  background-color: $success;
}

.label-info {
  background-color: $info;
}

.label-warning {
  background-color: $warning;
}

.label-danger {
  background-color: $danger;
}

.label-megna {
  background-color: #56c0d8;
}

.label-primary {
  background-color: $primary;
}

.label-purple {
  background-color: $purple;
}

.label-red {
  background-color: $red;
}

.label-inverse {
  background-color: #2f3d4a;
}

.label-default {
  background-color: #e9edf2;
}

.label-white {
  background-color: #ffffff;
}

.label-light-success {
  background-color: #e8fdeb;
  color: $success;
}

.label-light-info {
  background-color: #cfecfe;
  color: $info;
}

.label-light-warning {
  background-color: #fff8ec;
  color: $warning;
}

.label-light-danger {
  background-color: #f9e7eb;
  color: $danger;
}

.label-light-megna {
  background-color: #e0f2f4;
  color: #56c0d8;
}

.label-light-primary {
  background-color: #f1effd;
  color: $primary;
}

.label-light-inverse {
  background-color: #f6f6f6;
  color: #2f3d4a;
}

.label-quant{
  display: inline-block;
  max-width: 120px;
  padding: 3px 6px;
  text-align: left;
}


.mdi-checkbox-blank-circle{
  &.text-danger{
    background-color: $danger;
  }
  &.text-success{
    background-color: $success;
  }
  &.text-warning{
    background-color: $warning;
  }
}
span.mdi-checkbox-blank-circle{
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  &:before{
    content:"";
    display: none;
  }
}
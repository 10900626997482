.chat-blank{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  height: 100%;
  min-height: 300px;
  .chat-logo{
    max-width: 300px;
    margin-bottom: 30px;
    opacity: 0.5;
  }
  &__inner{
    text-align: center;
  }
}
.chat-actions{
  ul{
    list-style: none;
    margin: 0 8px 0 0;
    padding: 0;
    li{
      margin-right: 8px;
      a{
        display: block;
        color: #888;
        &:hover{
          color: $link-hover-color;
        }
      }
    }
  }
  .mdi{
    font-size: 24px;
  }
}

.chat-attach-group{

}

.chat-attach{
  display: inline-block;
  vertical-align: bottom;
  margin-right: 4px;
  margin-bottom: 8px;
  color: $body-color;
  &:hover{
    color: $body-color;
  }
}

.chat-file-icon{
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border: 1px solid $border-color;
  margin-bottom: 2px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include media-breakpoint-down(sm){
    width: 60px;
    height: 60px;
  }
  @include media-breakpoint-down(xs){
    width: 100px;
    height: 100px;
  }
  img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    @include media-breakpoint-down(sm){
      width: 60px;
      height: 60px;
    }
  }
  .mdi{
    font-size: 36px;
    color: #cccccc;
    @include media-breakpoint-down(sm){
      font-size: 24px;
    }
    @include media-breakpoint-down(xs){
      font-size: 36px;
    }
  }
}

.chat-meta{
  width: 100px;
  @include media-breakpoint-down(sm){
    width: 60px;
  }
  @include media-breakpoint-down(xs){
    width: 100px;
  }
}
.chat-file-name{
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.chat-file-size{
  font-size: 12px;
}

.chat-scroll-to-top{
  position: absolute;
  right: 0;
  z-index: 100;
  border: 0;
  bottom: 10px;
  right: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: rgba($gray-200,0.5);
  box-shadow: 0 1px 3px rgba(0,0,0,.25);
  transition: all ease 0.25s;
  outline: none;
  .badge{
    position: absolute;
    right: -4px;
    top: -8px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    line-height: 14px;
    font-weight: normal;
  }
  @include media-breakpoint-down(xs){
    display: none;
  }
  &:focus{
    outline: none;
  }
  &:hover{
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(0,0,0,.15);
    .svg-icon{
      fill: $secondary;
    }
  }
  .svg-icon{
    fill: $gray-500;
    font-size: 24px;
  }
}

.chat-notice{
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 10px;
  .btn{
    border-radius: 20px;
    &.is-hide{
      display: none !important;
    }
  }
}
.chat-main-box {
  position: relative;
  overflow: hidden;
  display: flex;
  .card-body{
    @media(max-width: 380px){
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  // LEFT
  .open-pnl{
    .open-panel{
      margin-left: 0!important;
      .mdi{
        transform: rotate(180deg);
      }
    }
  }
  .chat-left-aside {
    position: relative;
    width: 250px;
    float: left;
    z-index: 9;
    top: 0;
    bottom: 0;
    border-right: 1px solid #ced4da;
    margin-right: -1px;
    .open-panel {
      display: none;
      cursor: pointer;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 100;
      background-color: #fff;
      box-shadow: 2px 0 3px rgba(0, 0, 0, 0.2);
      border-radius: 0 100px 100px 0;
      line-height: 1;
      padding: 10px 2px 10px 0px;
      font-size: 24px;
      margin-left: 2px;
      transition: all ease 0.25s;
      .mdi{
        color: #888;
        line-height: 1;
        display: inline-block;
        vertical-align: middle;
        transition: transform 0.25s ease-out;
      }
    }
    .chat-left-inner {
      position: relative;
      min-height: 100%;
      .chatonline {
        position: relative;
        height: 90%;
      }
      .form-control {
        height: 60px;
        padding: 15px;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #ced4da;
        &:focus{
          box-shadow: 0 -2px 0 0 $info inset;
        }
      }
      .style-none {
        padding: 0;
        li {
          list-style: none;
          overflow: hidden;
          a {
            padding: 16px;
            &:hover,
            &.active{
              background: lighten($gray-200, 3%);
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  // RIGHT
  .chat-right-aside {
    width: calc(100% - 250px);
    float: left;
    border-left: 1px solid #ced4da;
    .chat-rbox {
      height: auto;
      position: relative;
      min-height: 200px;
    }
    .chat-list {
      max-height: none;
      height: 100%;
      padding-top: 0;
      .chat-text {
        border-radius: 6px;
      }
    }
    .card-body{
      position: relative;
    }
  }
}
.chat-rbox {
  position: relative;
}

.send-chat-box {
  position: relative;
  .form-control {
    border: none;
    border-top: 1px solid #ced4da;
    resize: none;
    height: 80px;
    padding-right: 180px;
    &:focus {
      border-color: #ced4da;
    }
  }
  .custom-send {
    position: absolute;
    right: 20px;
    bottom: 10px;
    .cst-icon {
      color: #67757c;
      margin-right: 10px;
    }
  }
}
.chat-new-request{
  padding: 12px 20px 12px;
  border-bottom: 1px solid $gray-400;
  h6{
    font-weight: 600;
  }
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      display: inline-block;
      margin-right: 20px;
    }
  }
}
.chat-textarea{
  height: 100%;
  min-height: 90px;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 64px;
  position: relative;
  box-shadow: none;
  &:focus {
    box-shadow: none;
  }
}
.card-body{
  .chat-send{
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
.chat-main-header{
  border-bottom: 1px solid #d0dace;
  padding: 12px 20px 12px;
  align-items: center;
  height: 60px;
  @include media-breakpoint-down(xs){
    padding-left: 15px;
    padding-right: 15px;
  }
  @media(max-width: 360px){
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    h3{
      margin-bottom: 10px;
    }
    .ml-auto{
      margin-left: 0!important;
      width: 100%;
    }
  }
  h3{
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.1;
    @include media-breakpoint-down(xs){
      font-size: 16px;
    }
  }
}


.chat-submit-block{
  display: flex;
  flex-direction: row;
  position: relative;
}

.chat-attach-block{
  border-top: 1px solid $border-color;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  font-size: 12px;
  color: $success;
}
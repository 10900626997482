.block-route{
  overflow: hidden;
  position: relative;
  z-index: 0;
  display: flex;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,.1);
  min-height: 500px;
  height: 600px;
  margin-bottom: 30px;
  &__item{
    margin-bottom: 30px;
    + .block-route__item{
      padding-top: 30px;
      border-top: 2px solid $border-color;
    }
  }
  &__inner{
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    position: absolute;
    top: 0;
  }
  &__aside{
    // min-height: 0px;
    // overflow-y: auto;
    background-color: #fff;
    position: absolute;
    width: 290px;
    height: 100%;
    flex: 0 0 290px;
    z-index: 11;
    opacity: 1;
    border: 1px solid darken($border-color,5%);

    transition-property: transform, opacity;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.0,0.0,0.2,1);
    transform: translateX(-290px);
    @include media-breakpoint-down(xs){
      width: calc(100% - 40px);
      transform-origin: 0 0;
      transform: translateX( -100% );
    };
    &.is-visible{
      position: relative;
      height: 100%;
      transform: translateX(0);
      @media (max-width: 1024px){
        position: absolute;
      }
      .block-route__inner{
        height: 100%;
        opacity: 1;
      }
      ~ .block-route__main{
        // padding-left: 50px;
      }
      .block-route__toggle{
        @media (max-width: 1024px){
          width: 40px;
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }
  &__main{
    height: 100%;
    width: 100%;
    flex: 0 1 auto;
    background-color: #fff;
    padding: 36px 15px 15px 15px;
    transition: all ease 0.25s;

    .d-flex{
      height: 100%;
      flex-direction: column;
    }
    .table{
      tr.is-over{
        color: #ccc;
      }
    }
  }
  &__toggle{
    position: absolute;
    top: 0;
    z-index: 100;
    left: 100%;
    height: 26px;
    width: 50px;
    text-align: center;
    cursor: pointer;
    line-height: 26px;
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.15);
    @media (max-width: 1024px){
      // width: 26px;
    }
    .mdi{
      font-size: 24px;
    }
  }
  &__car{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media(max-width: 500px){
      justify-content: flex-start;
    }
    .car{

    }
    .car-driver{
      flex: 0 1 130px;
      margin-left: 15px;
    }
  }
}

.car-driver{
  &__name{
    font-weight: 700;
    line-height: 1.2;
  }
}

.r-list{
  display: flex;
  padding-bottom: 20px;
  @media(max-width: 500px){
    flex-direction: column;
  }
  &__left{
    margin-right: 20px;
    @media(max-width: 500px){
      margin-bottom: 15px;
    }
  }
  &__center{
    margin-right: 20px;
    @media(max-width: 500px){
      margin-right: 0;
    }
  }
  &__right{
    margin-left: auto;
    @media(max-width: 500px){
      margin-left: 0;
    }
  }
  &__title{
    font-size: 20px;
    margin-bottom: 2px;
    line-height: 1.2;
    white-space: nowrap;
    b{
      font-weight: 700;
    }
  }
  &__date{
    font-size: 14px;
    color: #888;
    widows: nowrap;
  }
}

.m-route{
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  // height: 600px;
  // max-height: 100%;
}
.m-route-list{
  box-shadow: 0 5px 15px rgba(0,0,0,.1);
  &__item{
    cursor: pointer;
    padding: 10px 16px 10px 10px;
    border-bottom: 1px solid darken($border-color, 5%);
    transition: background-color ease 0.25s;
    &.is-active,
    &:hover{
      background-color: theme-color('primary');
      color: #fff;
      .car__number{
        color: #fff;
      }
      .m-route-list__title{
        background-color: rgba(255,255,255,.1);
        color: #fff;
      }
    }
    &:last-child{
      border-bottom: 0;
    }
    .d-flex{
      align-items: center;
    }
    &--undefined{
      min-height: inherit;
      .m-route-list__title{
        background-color: #ffd6d6;
        padding-left: 50px;
        line-height: 1.3;
        font-size: 15px;
        margin-bottom: -10px;
        min-height: 50px;
      }
    }
  }
  &__title{
    display: block;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -16px;
    margin-bottom: 6px;
    padding: 6px 16px 8px 10px;
    background-color: #f6f6f6;
    transition: background-color ease 0.1s;
  }
  &__date{
    float: right;
    font-size: 13px;
    line-height: 16px;
    margin-top: 4px;
    font-weight: 400;
  }
  &__car{
    .car__number{
      border: 0;
      padding-left: 0;
      padding-right: 0;
      min-width: inherit;
      background-color: transparent;
    }
  }
  &__driver{
    padding-left: 15px;
    margin-left: auto;
    text-align: right;
    line-height: 1.2;
    font-size: 13px;
    font-weight: 400;
  }
}

.delivery-list{
  &__item{
    position: relative;
    padding: 10px 18px 10px 44px;
    min-height: 64px;
    border-bottom: 1px solid #e6e6e6;
    cursor: pointer;
    &:first-child{
      border-top: 1px solid #e6e6e6;
    }
    &:hover{
      background-color: #f2f2f2;
      box-shadow: 0 5px 15px rgba(0,0,0,.2);
      .delivery-list__priority{
        display: block;
        box-shadow: 0 5px 10px rgba(0,0,0,.15);
      }
    }
    &.is-over{
      background-color: #fff;
      cursor: not-allowed;
      opacity: 0.5;
      &:hover{
        background-color: #fff;
        .delivery-list__priority{
          display: none;
        }
      }
    }
  }
  &__body{
    padding-right: 40px;
    .dropdown{
      position: absolute;
      right: 14px;
      top: 14px;
    }
  }
  &__number{
    position: absolute;
    left: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    padding: 0;
    font-size: 14px;
    border-radius: 50%;
    color: #fff;
    background-color: #bbb;
  }
  &__address{
    font-weight: 600;
    margin-bottom: 4px;
    position: relative;
    line-height: 1.3;
  }
  &__company{
    position: relative;
    font-size: 13px;
    color: #888;
  }
  &__footer{
    display: flex;
    justify-content: space-between;
  }
  &__date{
    color: #888;
    font-size: 13px;
    margin-left: auto;
  }
  &__time{
    position: relative;
    font-size: 13px;
    color: #888;
  }
  &__priority{
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    a{
      float: left;
      width: 40px;
      height: 40px;
      line-height: 38px;
      background-color: #f2f2f2;
      text-align: center;
      font-size: 24px;
      color: #333;
      text-decoration: none;
      border: 1px solid #e6e6e6;
      transition: all ease 0.25s;
      &:hover{
        background-color: $primary;
        border-color: $primary;
        color: #fff;
      }
      &:nth-child(2n){
        margin-left: -1px;
      }
    }
  }
}
.route-list{
  &__collapse{
    background-color: #bbb;
  }
}
$checkbox-and-radio-width:           20px !default;//                .checkbox & .radio
$checkbox-and-radio-border-color:    #6D6D6D !default;// .checkbox & .radio
$checkbox-and-radio-bg-checked:      $primary !default;//
$checkbox-and-radio-color-disabled:  #b2b2b2 !default;

.radio {
  margin-bottom: 0;
  padding-left: $checkbox-and-radio-width + 14px;
  line-height: 1.43;
  color: $body-color;
  margin-top: 0;
  cursor: pointer;
  position: relative;
  text-transform: none;
  &__group{
    .radio{
      display: block;
    }
    .radio + .radio{
      margin-top: 16px;
    }
  }
  span {
    transition: all ease 0.25s;
    // white-space: nowrap;
    font-weight: normal;
  }

  i {
    position: absolute;
    top: 50%;
    margin-top: -($checkbox-and-radio-width /2);
    left: 0;
    display: block;
    width: $checkbox-and-radio-width;
    height: $checkbox-and-radio-width;
    outline: none;
    border: 2px solid $checkbox-and-radio-border-color;
    background: #fff;
    transition: all ease 0.25s;
    border-radius: 50%;
  }

  input {
    position: absolute;
    left: -9999px;
    appearance: none;

    ~ i {
      &:after {
        transition: background-color ease 0.25s;
      }
    }

    &:checked {
      ~ i {
        border-color: $checkbox-and-radio-bg-checked;

        &:after {
          position: absolute;
          content: '';
          top: ($checkbox-and-radio-width - ($checkbox-and-radio-width/2))/2 - 2;
          left: ($checkbox-and-radio-width - ($checkbox-and-radio-width/2))/2 - 2;
          width: $checkbox-and-radio-width/2;
          height: $checkbox-and-radio-width/2;
          border-radius: 50%;
          background-color: $checkbox-and-radio-bg-checked;
        }
      }

      &:disabled {
        ~ i {
          cursor: not-allowed;

          &:after {
            background-color: $checkbox-and-radio-color-disabled;
          }
        }
      }
    }

    &:disabled {
      ~ i {
        cursor: not-allowed;
        border-color: $checkbox-and-radio-color-disabled;
      }

      ~ span {
        color: $checkbox-and-radio-color-disabled;
      }
    }
  }

  &:hover {
    span {
      color: $body-color;
    }
  }
}

.fpanel{
  overflow: hidden;
  // height: 100vh !important;
  padding: 0 0;
  @include media-breakpoint-down(xs){
    width: 100%!important;
  }
  .fpanel__tablesaw{
    max-height:calc(100vh - 230px);
    @include media-breakpoint-down(sm){
      max-height:80vh;
    }
  }
  .card{
    height: 100%;
    overflow: auto;
    margin-bottom: 0;
    border-right: 0;
    border-radius: 0;
    border-radius: 0;
  }
  .card-title{
    @include media-breakpoint-down(sm){
      font-size: 16px;
    }
  }
  // trigger
  &__trigger{
    // width: 244px;
    background-color: rgba(lighten(#ffffff,50%), 0.85);
    cursor: pointer;
    z-index: 10;
    position: fixed;
    right: 0;
    top: 200px;
    padding: 10px;
    box-shadow: 
      0 1px 1px 0 rgba(0,0,0,.1),
      0 2px 2px 0 rgba(0,0,0,.1),
      0 4px 4px 0 rgba(0,0,0,.1),
      0 8px 8px 0 rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 2px 0 0 2px;
    border-right: 0;
    // transform: translateX(204px);
    // transition: transform ease-in-out 0.3s;
    @include media-breakpoint-down(xs){
      position: relative;
      top: 0;
      border: 1px solid rgba(0,0,0,.125);
      margin-bottom: 20px;
    }
    &:hover{
      // transform: translateX(0);
    }
    &__inner{
      // padding-left: 30px;
    }
    h6{
      font-size: 15px;
      font-weight: 700;
      padding-bottom: 8px;
      border-bottom: 1px solid #ddd;
    }
    &:hover{
      background-color: rgba(lighten(#fff,50%), 1);
    }
  }
}

@mixin icon(){
  color: #888;
  font-size: 18px;
  width: 20px;
  line-height: 20px;
  height: 20px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}

.listbox{
  margin-bottom: 40px;
  margin-top: 50px;
  &__header{
    z-index: 10;
    display: flex;
    align-items: center;
    position: fixed;
    background-color: $primary;
    color: #fff;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    padding: 0 4px 0 1px;
    border-bottom: 1px solid #e6e6e6;
    text-align: center;
    .d-flex{
      width: 100%;
      align-items: center;
      &__month{
        width: 60%;
        padding: 0 10px;
      }
      &__year{
        width: 40%;
        padding: 0 10px;
      }
    }
    .listbox__curday{
      font-size: 16px;
      color: #fff;
      font-weight: 600;
    }
  }
  &__back{
    &.btn{
      color: #fff;
      margin-right: 10px;
      &:hover{
        color: #fff;
      }
    }
  }
  &__item{
    position: relative;
    padding: 10px 18px 10px 10px;
    min-height: 64px;
    border-bottom: 1px solid #e6e6e6;
    cursor: pointer;
    // Заказ доставлен
    &.is-done{
      opacity: 0.6;
      .label-themecolor{
        background-color: $success;
      }
    }
    // Вчерашний день
    &.is-over{
      background-color: #fff;
      cursor: not-allowed;
      > div{
        opacity: 0.5;
      }
      &:hover{
        background-color: #fff;
      }
    }
    // Текущий день
    &.is-current{
      background-color: #dcefff;
      &:hover{
        background-color: darken(#dcefff,2%);
      }
    }
    &:hover{
      background-color: #fafafa;
      outline: none;
    }
    .collapsing,
    .collapse{
      padding-left: 30px;
    }
  }
  &__number{
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  &__title{
    font-weight: 600;
    margin-bottom: 4px;
    position: relative;
    line-height: 1.3;
  }
  &__address{
    position: relative;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 4px;
    color: #888;
  }
  &__phone{
    font-weight: 600;
    position: relative;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 24px;
    .mdi{
      @include icon();
    }
    a{
      color: #333;
      &:hover{
        text-decoration: none;
      }
    }
  }
  &__comment{
    font-size: 13px;
    line-height: 16px;
    color: #888;
    margin-bottom: 4px;
    p{
      margin-bottom: 0;
    }
  }
  &__info{
    
  }
  &__count{
    float: right;
    text-align: center;
    line-height: 1.2;
    .status{
      display: block;
      text-align: left;
      font-size: 13px;
      min-width: 40px;
      .mdi{
        font-size: 18px;
        width: 20px;
        vertical-align: middle;
      }
      b{
        font-size: 14px;
      }
    }
    .text-muted{
      margin-top: 4px;
    }
  }
  &__collapse{
    position: relative;
    display: block;
    padding: 4px 30px 4px 30px;
    color: $body-color;
    &:hover{
      text-decoration: none;
      color: $body-color;
    }
    .label{
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      padding: 0;
      font-size: 14px;
    }
    .listbox__arrow{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      font-size: 24px;
      width: 30px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      vertical-align: middle;
      color: #888;
    }
    &.collapsed{
      .listbox__arrow{
        &:before{
          content: "\F140";
        }
      }
    }
  }
  // CALENDAR
  &__day{
    font-weight: 600;
    font-size: 16px;
    @include media-breakpoint-down(xs){
      font-size: 14px;
    }
  }
  &__week{
    color: #888;
    span{
      font-size: 13px;
    }
  }
  &__invoice{
    padding: 8px 0 0;
    .btn{
      margin-bottom: 8px;
    }
    .btn-outline-dark{
      color: #888;
      border-color: #888;
      &:hover{
        color: #fff;
      }
    }
  }
}

.bonus-amount {
  padding: 1rem 1.5rem;

  .row {
    justify-content: space-between;

    h4 {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(md) {
    h4 {
      font-size: 16px;

      &:not(:last-of-type) {
        @include media-breakpoint-down(sm) {
          margin-bottom: 10px;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    width: 400px;
  }
}
.categories {
  flex-wrap: wrap;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 20px;
  .current-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    button {
      position: relative;
      min-width: max-content;
      display: flex;
      align-items: center;
      .icons {
        position: relative;
        bottom: 2px;
        margin-left: 10px;
        margin-right: -8px;
      }
      .svg-icon {
        fill: #004c9a;
        margin-right: 3px;
        line-height: 0;
        &.icon-plus {
          fill: $white;
          font-size: 18px;
        }
      }
      &:hover .svg-icon {
        fill: $white;
      }
    }
  }
}
.prizes-list {
  margin-left: 0;
  align-content: start;
  .card-wrapper {
    padding: 0 13px 0 0;
  }
}
.prize-orders {
  padding-right: 0;
  min-width: 280px;
  .card-header {
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .orders {
    margin-bottom: 10px;
    &__table {
      border-collapse: separate;
      border-spacing: 2px 10px;
      margin: 5px 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $gray-300;
      .prize-quantity {
        white-space: nowrap;
        font-weight: bold;
        text-align: right;
      }
    }
    .manager-totals {
      padding: 15px 10px 20px;
      display: flex;
      justify-content: space-between;
    }
    .order {
      padding: 10px 10px;
      .row.no-gutters {
        display: flex;
        align-items: center;
        h5 {
          margin-bottom: 0;
        }
        .card-img {
          min-height: 75px;
          min-width: 90px;
          max-height: 266px;
          max-width: 354px;
          object-fit: contain;
        }
        .card-title {
          padding: 0 5px 0 10px;
          font-size: 14px;
          line-height: 1.25;
          //text-align: justify;
        }
      }
      .card-body {
        padding: 5px 8px;
        .row {
          display: flex;
          align-items: center;
          justify-content: space-around;
          text-align: center;
          .quantity-input {
            display: flex;
            align-items: center;
            justify-content: start;
            padding: 10px;
            .num-block {
              float: left;
              //padding: 5px;
              .num-in {
                background: #ffffff;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
                border-radius: 15px;
                height: 30px;
                width: 90px;
                float: left;

                input {
                  -moz-appearance: textfield;
                  background-color: $gray-200;
                  font-size: 15px;
                  font-weight: bold;
                  float: left;
                  padding: 2px;
                  width: 30%;
                  height: 30px;
                  border: none;
                  text-align: center;
                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                }
                span {
                  width: 35%;
                  display: block;
                  height: 30px;
                  float: left;
                  position: relative;
                  cursor: pointer;

                  &:before,
                  &:after {
                    content: "";
                    position: absolute;
                    background-color: #667780;
                    height: 2px;
                    width: 10px;
                    top: 50%;
                    left: 50%;
                    margin-top: -1px;
                    margin-left: -5px;
                  }
                  &.plus:after {
                    transform: rotate(90deg);
                  }
                }
              }
            }
          }
          .prize-item-total {
            text-align: right;
          }
        }
      }
      .details {
        padding: 10px 5px 10px;
        margin-bottom: 0;
        line-height: line-height(sm);
      }

      .bottom-line {
        padding-top: 20px;
        border-bottom: 1px solid $gray-300;
        width: 100%;
      }
    }
  }
  .orders-info {
    padding: 10px 10px 20px;
    .totals {
      margin-bottom: 20px;
      .orders-total,
      .bonuses-left {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        p {
          margin-bottom: 5px;
        }
      }
    }
    .delivery-options {
      margin-bottom: 20px;
    }
    .order-comment {
      margin-bottom: 20px;
      textarea {
        resize: none;
        height: 120px;
      }
    }
    button[type="submit"] {
      margin: 0 auto;
      display: block;
      width: 90%;
    }
  }
}
.bonus-bold {
  font-size: 18px;
  font-weight: bold;
  &.overload {
    color: $danger;
  }
}

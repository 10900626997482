.chess-bar{
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  &__section{
    display: inline-block;
    vertical-align: top;
    padding: 4px 0;
  }
  &__block{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 20px;
    border: 1px solid #888;
    margin-right: -1px;
    margin-bottom: -1px;
    font-size: 13px;
    line-height: 1.2;
    &--creator{
      width: 30px;
    }
    &--status{
      background-color: transparent;
      color: #666666;
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &[class^="bg"],
    &[class*=" bg"]{
      color: #fff;
      cursor: pointer;
      &:hover{
        transform: scale(1.1);
        box-shadow: 0 5px 10px rgab(0,0,0,0.15);
      }
    }
  }
}

.chess-popover{
  font-size: 14px;
  dl{
    margin-bottom: 0;
  }
}

.action {
  background-color: #fff;
  margin-bottom: 2rem;
  border-radius: 8px;
  border: 1px solid $table-border-color;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  transition: box-shadow ease 0.25s;
  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05), 0 4px 4px rgba(0, 0, 0, 0.05),
      0 8px 8px rgba(0, 0, 0, 0.05), 0 16px 16px rgba(0, 0, 0, 0.05);
    // box-shadow: 0 10px 40px 0 rgba(0,46,103,.07), 0 2px 9px 0 rgba(0,46,103,.06);
  }
  &__picture {
    display: block;
    width: 100%;
    height: 0;
    // padding-top: 56.25%;
    padding-top: 75%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    text-decoration: none;
    border-bottom: 1px solid $border-color;
  }
  &__body {
    padding: 1rem 1rem 1rem;
  }
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 8px;
    a {
      text-decoration: none;
      color: $body-color;
      &:hover {
        color: $link-hover-color;
      }
    }
  }
  &__description {
    font-size: 14px;
  }
  &__footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    padding: 0 1rem 1rem;
    // padding: 0 0 1rem;
    .btn {
      margin-left: auto;
      display: flex;
      align-items: center;
      .mdi {
        font-size: 16px;
        margin-left: 4px;
      }
    }
  }
  &__date {
    color: #888;
    font-size: 14px;
  }
}

.action-detail {
  &__date {
    display: block;
    color: $gray-600;
    margin-bottom: 1rem;
  }
  &__image {
    margin-bottom: 2rem;
  }
  &__body {
    margin-bottom: 2rem;
  }
  &__back {
  }
}

@mixin icons(){
  color: #888;
  font-size: 18px;
  width: 20px;
  line-height: 20px;
  height: 20px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
.route{
  position: relative;
  height: 100%;
}
.filter-route{
  display: flex;
  &__item{
    display: flex;
    align-items: center;
    padding-right: 8px;
    label{
      white-space: nowrap;
      margin-right: 10px;
      margin-bottom: 0;
      @media(max-width: 400px){
        display: none;
      }
    }
  }
}

.route-list{
  margin-bottom: 40px;
  position: relative;
  background-color: #fff;
  &__status{
    position: absolute;
    bottom: 0;
    right: 0;
    // transform: translateX(-100%);
    z-index: 1;
    &.success{
      > div{
        background-color: theme-color(success);
      }
    }
    &.warning{
      > div{
        background-color: $yellow;
        color: #000;
      }
    }
    > div{
      width: 102px;
      // transform: rotate(-90deg);
      // transform-origin: right top;
      text-align: center;
      color: #fff;
      padding-left: 4px;
      padding-right: 4px;
      letter-spacing: 0.02em;
      font-size: 10px;
      font-weight: normal;
      box-shadow: -1px -1px 2px rgba(0,0,0,.2);
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
    }
  }
  &__undefined{
    font-size: 18px;
    position: absolute;
    left: 10px;
    top: 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    width: 30px;
    background-color: #fff;
    color: #333;
    &.is-active{
      &:before{
        content: "";
        left: -5px;
        top: -5px;
        right: -5px;
        bottom: -5px;
        position: absolute;
        z-index: 10;
        border: 6px solid $danger;
        border-radius: 70px;
        animation: heartbit 1s ease-out;
        animation-iteration-count: infinite;
      }
    }
  }
  &__item{
    position: relative;
    // padding: 10px 18px 10px 10px;
    min-height: 64px;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    &.route-list__item--undefined{
      min-height: inherit;
      .route-list__collapse,
      .delivery-list__number{
        background-color: $primary;
        color: #fff;
      }
      .route-list__title{
        margin-bottom: -10px;
        font-size: 15px;
        line-height: 1.3;
        padding-left: 50px;
      }
    }
    // Заказ доставлен
    &.is-done{
      opacity: 0.6;
      .label-themecolor{
        background-color: $success;
      }
    }
    // Вчерашний день
    &.is-over{
      background-color: #fff;
      cursor: not-allowed;
      > div{
        opacity: 0.5;
      }
      &:hover{
        background-color: #fff;
      }
    }
    // Текущий день
    &.is-current{
      background-color: #dcefff;
      &:hover{
        background-color: darken(#dcefff,2%);
      }
    }
    .collapsing,
    .collapse{
      padding-left: 0px;
    }
  }
  &__number{
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  &__title{
    display: block;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -14px;
    padding: 6px 38px 8px 10px;
    background-color: rgba(0,0,0,.4);
    color: #fff;
  }
  .d-flex{
    align-items: center;
    padding: 10px 0 0;
    font-size: 13px;
    line-height: 1.3;
    .dropdown{
      margin-left: 10px;
    }
  }
  &__driver{
    width: 50%;
    position: relative;
    text-align: right;
    padding: 5px 0 7px 0;
  }
  &__car{
    width: 50%;
    .car__number{
      @media (max-width: 360px){
        font-size: 11px;
        min-width: inherit;
      }
    }
  }
  &__date{
    float: right;
    position: relative;
    font-size: 13px;
    line-height: 16px;
    margin-top: 4px;
    color: #fff;
    font-weight: normal;
    @media(max-width: 360px){
      font-size: 10px;
    }
  }
  &__collapse{
    position: relative;
    display: block;
    padding: 10px 14px 16px 10px;
    color: $body-color;
    @media(max-width: 360px){
      padding-left: 8px;
      padding-right: 8px;
    }
    &:hover{
      text-decoration: none;
      color: $body-color;
    }
    .route-list__arrow{
      position: absolute;
      top: 8px;
      right: 10px;
      font-size: 24px;
      width: 24px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      vertical-align: middle;
      color: #fff;
      z-index: 10;
    }
    &.collapsed{
      .route-list__arrow{
        &:before{
          content: "\F140";
        }
      }
    }
  }
}

.car{
  position: relative;
  padding: 0 0 0 40px;
  .mdi{
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
  }
  &__number{
    min-width: 86px;
    text-align: center;
    display: inline-block;
    background-color: #fff;
    padding: 1px 4px;
    border: 1px solid $primary;
    color: $primary;
    font-weight: normal;
    margin-bottom: 2px;
    font-weight: bold;
  }
  &__name{
    display: block;
    font-size: 12px;
  }
}

.move-blocks{
  position: relative;
  z-index: 1;
  .js-col-wrapper{

  }
  .js-col-left,
  .js-col-right{

  }
}
.js-move-panel{

}
.move-handle{
  &__wrapper{
    z-index: 2;
    cursor: col-resize;
    width: 30px;
    position: relative;
    left: 50%;
    margin-left: -15px;
    position: absolute;
    top: 0;
    bottom: 0;
    &:hover{
      &:before{
        content: "";
        width: 2px;
        height: 100%;
        position: absolute;
        left: 50%;
        margin-left: -1px;
        background-color: lighten($primary,30%);
      }
    }
  }
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  background-color: $primary;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 0;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
  transition: background-color ease 0.25s;
  &:hover{
    background-color: lighten($primary, 20%);
  }
  .mdi{
    font-size: 16px;
  }
}
.move-panel{
  &.card{
    margin: 0;
    z-index: 10;
  }
  .card-title{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .card-body{
    padding-top: 10px;
    padding-bottom: 10px;
    .btn-group{
      .btn-icon{
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-top: -4px;
        font-size: 20px;
      }
    }
  }
  .form-group{
    margin-bottom: 10px;
  }
  .panel{
    .btn{
      margin-bottom: 5px;
    }
  }
  .table.table-sm{
    margin: 0;
    td,
    th{
      font-size: 12px;
      padding: 4px 8px;
    }
  }
}

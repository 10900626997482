.info-block{
  position: relative;
  margin-bottom: 20px;
  @include media-breakpoint-down(sm){
    margin-bottom: 20px;
  }
  @include media-breakpoint-down(xs){
    padding-right: 60px;
  }
  &__toggle{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    padding: 0!important;
    .mdi{
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform:  translate3d(-50%,-50%,0);
    }
    @include media-breakpoint-up(sm){
      display: none;
    }
  }
  .text-line-through{
    text-decoration: line-through;
    color: #888;
  }
  .card{
    text-align: center;
    @include media-breakpoint-up(sm){
      display: flex !important;
    }
    @include media-breakpoint-down(xs){
      margin-bottom: 4px;
      display: none;
      &:last-child{
        display: flex!important;
        margin-bottom: 0;
      }
    }
    .card-body{
      padding: 6px 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      line-height: 1.3;
      @include media-breakpoint-down(sm){
        font-size: 12px;
        padding: 10px;
        line-height: 1.3;
      }
    }
    h5{
      font-weight: 600;
      margin-bottom: 0;
      font-size: 15px;
      @include media-breakpoint-down(sm){
        font-size: 14px;
      }
    }
  }
}

.auth-box{
  position: relative;
  max-width: 800px;
  width: 100%;
  margin: auto;
  flex: 1 1 auto;
  border-radius: $border-radius;
  box-shadow: 0 4px 10px rgba(0,0,0,.2);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  @include media-breakpoint-down(sm){
    flex: 1;
    align-self: center;
    flex-direction: column;
    margin: 0 10px;
    box-shadow: none;
    border-radius: 0;
  }
  &__left{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    background-color: #f8f9fa;
    @include media-breakpoint-down(sm){
      width: 100%;
      background: none;
      padding-top: 10px;
    }
    @include media-breakpoint-down(xs){
      padding-bottom: 20px;
    }
  }
  &__right{
    width: 55%;
    @include media-breakpoint-down(sm){
      width: 100%;
    }
  }
  &__logo{
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &__logo-link{
    height: 100%;
  }
  &__image{
    width: 100%;
    height: 100%;
    @include media-breakpoint-down(sm){
      max-height: 400px;
    }
  }
}

.auth-logo{
  width: 220px;
  padding: 30px;
  box-sizing: content-box;
  &__subtitle{
    font-weight: 600;
    color: #333;
  }
}

.auth{
  &__inner{
    flex: 1;
    padding: 64px 72px;
    background-color: #fff;
    @include media-breakpoint-down(sm){
      padding: 48px 56px;
    }
    @include media-breakpoint-down(xs){
      padding: 32px 32px;
    }
    .checkbox{
      margin-top: 0;
      @include media-breakpoint-down(xs){
        margin-bottom: 8px;
      }
    }
    .d-flex{
      @media (max-width: 340px){
        flex-direction: column;
        .ml-auto{
          margin-left: 0!important;
        }
      }
    }
  }
  &__title{
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.5rem;
    @include media-breakpoint-down(sm){
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(xs){
      font-size: 1.25rem;
    }
  }
  label{
    font-weight: 600;
  }
}

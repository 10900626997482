.filter-nav{
  display: none;
  @media(min-width: 961px){
    display: block !important;
  }
  &__toggle{
    margin-bottom: 20px!important;
    &.is-active{
      background-color: $secondary;
      border-color: $secondary;
    }
    @media(min-width: 961px){
      display: none;
      margin-bottom: 0!important;
    }
  }
  &__close{
    background-color: #fff;
    border: 0;
    &.is-active{
      background-color: #fff;
      border: 0;
    }
  }
}

.filter{
  display: none;
  @media(min-width: 961px){
    display: block !important;
  }
  .card{
    @include media-breakpoint-up(md){
      .form-group{
        margin-bottom: 0;
      }
    }
  }
  &__toggle{
    margin-bottom: 20px!important;
    &.is-active{
      background-color: $secondary;
      border-color: $secondary;
    }
    @media(min-width: 961px){
      display: none;
      margin-bottom: 0!important;
    }
  }
}
.help-list{
  &__item{
    margin-bottom: 20px;
    @include  clearfix();
  }
  &__title{
    font-size: 15px;
    margin-bottom: 6px;
  }
  &__date{
    font-size: 12px;
    color: #888;
    margin-bottom: 4px;
  }
  &__body{
    overflow: hidden;
  }
  &__picture{
    float: left;
    margin-right: 20px;
    width: 160px;
    border: 1px solid #e2e2e2;
    @media(max-width: 480px){
      width: 100px;
    }
    @media(max-width: 340px){
      width: 100%;
      float: none;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

.video{
  max-width: 800px;
  margin-bottom: 30px;
}

.quantity{
  display: inline-block;
  vertical-align: middle;
  position: relative;
  &__minus,
  &__plus{
    width: 30px;
    height: 36px;
    border: 0;
    line-height: 38px;
    text-align: center;
    top: 0;
    position: absolute;
    color: #888888;
    &:hover{
      color: $body-color;
    }
    &:hover,
    &:focus,
    &:active{
      border: 0;
    }
  }
  &__minus{
    left: 0;
  }
  &__plus{
    right: 0;
  }
  .quantity__input{
    color: #888888;
    height: $input-height;
    background-color: #fff;
    border-radius: 2px;
    width: 90px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

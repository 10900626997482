$sidebar-bg: #101E65;
$primary-bg: #187f03;

.topbar {
  background: #fff;
}

.topbar .top-navbar .navbar-header .navbar-brand .light-logo {
  display: none;
}

.topbar .top-navbar .navbar-nav > .nav-item > span {
  color: $link-color;
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link {
  color: $gray-500;
}

.topbar .navbar-light .navbar-nav.navbar-nav__second .nav-item > a.nav-link {
  @include media-breakpoint-down(xs) {
    color: $gray-700;
  }
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link:focus,
.topbar .navbar-light .navbar-nav .nav-item > a.nav-link:hover {
  color: $body-color !important;
}

/*******************
/*General Elements
*******************/

a {
  color: $primary;
}

//a.link:focus,
//a.link:hover {
//  color: $secondary !important;
//}

.bg-theme {
  background-color: $secondary !important;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: $secondary;
  border-color: $secondary;
}

.profile-tab li a.nav-link.active {
  border-bottom: 2px solid $primary;
  color: $body-color;
}
.profile-tab li a.nav-link:hover {
  color: $primary;
}

.user-profile .profile-text a {
  color: $gray-500 !important;
}

.card-no-border .sidebar-footer {
  background: $primary;
}

.label-themecolor {
  background: $danger;
  color: #fff;
}

/*******************
/*sidebar navigation
*******************/
.card-no-border .left-sidebar {
  background-color: $sidebar-bg;
}

.mini-sidebar .sidebar-nav {
  background: transparent;
}

.mini-sidebar {
  .left-sidebar {
    background-image: none;
  }
}

.sidebar-nav > ul > li.active > a {
  color: #ffffff;
  border-color: $primary-bg;
  background-color: $primary-bg;
}

.sidebar-nav > ul > li.active > a i {
  color: #ffffff;
}
.sidebar-nav .active {
  .label-themecolor {
    background-color: $danger;
  }
}
.sidebar-nav ul li a.active,
.sidebar-nav ul li a:hover {
  color: #fff;
}

.sidebar-nav ul li a.active i,
.sidebar-nav ul li a:hover i {
  color: #fff;
}

.sidebar-nav ul li.nav-small-cap {
  color: $gray-500;
}
@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
    background: $primary;
    color: #fff;
  }
  .mini-sidebar .sidebar-nav > ul > li:hover i {
    color: #fff;
  }
  .mini-sidebar .sidebar-nav > ul > li a:hover {
    font-weight: 600;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li ul {
    background: $primary;
  }
  .mini-sidebar .sidebar-nav ul li a {
    color: #fff;
  }
}

.fix-header{
  .topbar {
    position: fixed;
    width: 100%;
  }
  .page-wrapper {
    padding-top: 70px;
  }
  .right-side-panel {
    top: 70px;
    height: calc(100% - 70px);
  }
}

.user-text{
  position: relative;
  display: inline-block;
  text-align: center;
  background: #fff;
  font-weight: 600;
  font-size: 14px;
  color: #333;
  border-radius: 50px;
  height: 32px;
  line-height: 32px;
  width: 32px;
}

.user-picture {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 32px;
  margin-right: 8px;
  flex: 0 0 auto;
}


.topbar {
  position: relative;
  z-index: 50;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  .navbar-collapse {
    padding: 0 10px;
    justify-content: flex-end;
    .navbar-nav{
      // margin-left: auto;
    }
  }
  .top-navbar {
    min-height: 70px;
    padding: 0;
    .navbar-header {
      line-height: 55px;
      // padding-left: 10px;
      .navbar-brand {
        margin-right: 0;
        padding-bottom: 0;
        padding-top: 0;
        b {
          line-height: 70px;
          display: inline-block;
        }
        .light-logo {
          display: none;
        }
      }
      .navbar-nav{
        > .nav-item{
          > .nav-link {
            padding-left: 23px;
            padding-right: 23px;
          }
        }
      }


    }
    .dropdown-toggle{
      &::after {
        display: none;
      }
    }
    .navbar-nav{
      > .nav-item{
        &.show {
          background: rgba(0, 0, 0, 0.05);
        }
        > span {
          line-height: 65px;
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
          padding: 0 10px;
        }
        > .nav-link {
          padding-left: 15px;
          padding-right: 15px;
          font-size: 24px;
          line-height: 54px;
        }
      }
    }
  }
  // profile
  .profile-pic {
    width: 30px;
    border-radius: 100%;
    margin-top: -5px;
  }
  .user-text{
    top: -5px;
  }
  .dropdown-menu {
    border-color: rgba(120, 130, 140, 0.13);
    .dropdown-item {
      padding: 7px 1.5rem;
    }
  }
  ul{
    &.dropdown-user {
      padding: 0;
      min-width: 270px;
      li{
        a {
          padding: 9px 15px;
          display: block;
          color: $gray-600;
          &:hover {
            background: $gray-100;
            color: $body-color;
            text-decoration: none;
          }
        }
      }
      li {
        list-style: none;
        padding: 0;
        margin: 0;
        &.divider {
          height: 1px;
          margin: 9px 0;
          overflow: hidden;
          background-color: rgba(120, 130, 140, 0.13);
        }
        .dw-user-box {
          padding: 10px 15px;
          position: relative;
          .u-text {
            display: block;
            padding-left: 60px;
            h4 {
              margin: 0;
              font-size: 15px;
            }
            p {
              margin-bottom: 4px;
              font-size: 12px;
            }
            .btn {
              color: #ffffff;
              font-size: 12px;
              padding: 5px 10px;
              display: inline-block;
              &:hover {
                background: $secondary;
              }
            }
          }
          .u-img {
            width: 50px;
            position: absolute;
            left: 10px;
            top: 10px;
            img {
              width: 100%;
              border-radius: 5px;
            }
            .user-text{
              display: inline-block;
              text-align: center;
              width: 100%;
              height: 50px;
              background: #dadada;
              line-height: 50px;
              font-weight: 600;
              font-size: 18px;
              color: $body-color;
              border-radius: 50px;
            }
          }
        }
      }
    }
  }
}

// .mini-sidebar{
//   .top-navbar{
//     .navbar-header {
//       width: 70px;
//       text-align: center;
//       -webkit-flex-shrink: 0;
//       -ms-flex-negative: 0;
//       flex-shrink: 0;
//       padding-left: 0;
//     }
//   }
// }
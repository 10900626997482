.card-collapse{
  .card-title{
    
  }
  .checkout-body{
    display: none;
  }
  .checkout-footer{
    display: block;
  }
}


.checkout-goods{
  .tablesaw-overflow{
    max-height: 540px;
  }
}

.checkout-body{

}
.checkout-footer{
  display: none;
}

.list-radio{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1rem;
  &__radio{
    position: absolute;
    opacity: 0;
    &:checked ~ .list-radio__inner{
      box-shadow: 0 0 0 2px $primary;
    }
  }
  &__item{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%;
    padding-left: 1rem;
    margin-top: 1rem;
    @include media-breakpoint-down(xs){
      width: 100%;
    }
  }
  &__label{
    display: inline-block;
    margin: 0;
    cursor: pointer;
    min-height: 100%;
  }
  &__inner{
    display: flex;
    flex-direction: column;
    min-height: 100%;
    box-shadow:0 0 0 1px $border-color;
    padding: 1rem;
    border-radius: 6px;
  }
  &__title{
    font-weight: 700;
    margin-bottom: 8px;
  }
  &__desc{
    color: #999;
  }
  &__bottom{
    margin-top: auto;
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
  }
  &__date{

  }
  &__price{

  }
}

.checkout-delivery{
  margin-top: 1rem;
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 6px;
  border: 2px solid $primary;
  @include media-breakpoint-down(xs){
    padding: 1rem;
  }
  h4{
    margin-bottom: 1em;
  }
  .btn{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
$menu-item-link: #f1f1f1 !default;

.scroll-sidebar {
  height: calc(100% - 30px);
  // overflow: hidden !important;
  .scrollbar-macosx{
    height: 100%;
    >.scroll-element.scroll-y{
      right: auto;
      left: 10px;
    }
  }
}

.collapse.in {
  display: block;
}


.sidebar-nav{
  padding: 15px 0 0;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
  .user-profile{
    .user-text{
      flex-shrink: 0;
      margin-right: 10px;
    }
    &.active{
      > a{
        background-color: $primary;
      }
    }
    > ul {
      padding-left: 40px;
    }
    > a{
      img {
        width: 32px;
        border-radius: 100%;
        margin-right: 10px;
      }
    }
  }
  .region{
    color: #fff;
    &.active{
      .mdi{
        color: $body-color;
      }
    }
    &__inner{
      padding: 8px 15px 8px 15px;
      .mdi{
        width: 30px;
        font-size: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        color: $primary;
        text-align: center;
      }
    }
  }
}

.sidebar-nav ul li a {
  color: $menu-item-link;
  padding: 8px 15px 8px 15px;
  display: block;
  font-size: 14px;
  font-weight: 400;
  &.has-arrow{
    padding-right: 35px;
  }
}
.sidebar-nav > ul > li > a {
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-nav .hide-menu {
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-nav ul li.active > a,
.sidebar-nav ul li a:hover {
  color: #398bf7;
  text-decoration: none;
}

.sidebar-nav ul li.active > a i,
.sidebar-nav ul li a:hover i {
  color: #398bf7;
}

.sidebar-nav ul li.active > a {
  font-weight: 600;
  color: #ffffff;
}

.sidebar-nav ul li ul {
  padding-left: 35px;
}

.sidebar-nav ul li ul li a {
  padding: 7px 35px 7px 15px;
}

.sidebar-nav ul li ul ul {
  padding-left: 15px;
}

.sidebar-nav ul li.nav-small-cap {
  font-size: 12px;
  margin-bottom: 0;
  padding: 14px 14px 14px 20px;
  color: #263238;
  font-weight: 600;
}

.sidebar-nav ul li.nav-devider {
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  display: block;
  margin: 15px 0;
}

.sidebar-nav > ul > li > a i {
  width: 30px;
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  color: $menu-item-link;
}

.sidebar-nav > ul > li > a .label {
  float: right;
  margin-left: auto;
}

.sidebar-nav > ul > li.active > a {
  font-weight: 400;
  // background: #ffffff;
  color: #26c6da;
}

.sidebar-nav > ul > li {
  margin-bottom: 5px;
}

.sidebar-nav > ul > li.active > a {
  color: #398bf7;
  font-weight: 600;
}

.sidebar-nav > ul > li.active > a i {
  color: #398bf7;
}

.sidebar-nav .waves-effect {
  transition: none;
  -webkit-transition: none;
  -o-transition: none;
}

.sidebar-nav{
  .has-arrow {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      width: 7px;
      height: 7px;
      border-width: 1px 0 0 1px;
      border-style: solid;
      border-color: rgba(255,255,255,0.8);
      right: 1em;
      transform: rotate(135deg) translate(0, -50%);
      transform-origin: top;
      top: 47%;
      transition: all 0.3s ease-out;
    }
  }
}

.sidebar-nav .active > .has-arrow::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after,
.sidebar-nav li > .has-arrow.active::after {
  top: 45%;
  width: 7px;
  transform: rotate(-135deg) translate(0, -50%);
}

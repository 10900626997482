.delivery{
  margin-bottom: 30px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
  border: 1px solid rgba(0,0,0,.125);
  position: relative;
  &.is-fullscreen{
    z-index: 999;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border: 0;
    box-shadow: none;
    .delivery__main{
      height: calc(100% - 60px);
    }
  }
  &__header{
    z-index: 1;
    position: relative;
    height: 60px;
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
    h3{
      margin: 0;
      @include media-breakpoint-down(xs){
        font-size: 14px
      }
    }
    .d-flex{
      align-items: center;
      justify-content: flex-end;
    }
  }
  &__main{
    position: relative;
    background-color: #fff;
    height: 600px;
    overflow: hidden;
  }
}

.delivery-aside{
  width: 320px;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  z-index: 3;
  opacity: 1;

  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.0,0.0,0.2,1);
  transform: translateX(-320px);

  @include media-breakpoint-down(xs){
    width: calc(100% - 40px);
    transform-origin: 0 0;
    transform: translateX( -100% );
  };
  &__inner{
    border-right: 1px solid #e6e6e6;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;

  }
  &.is-visible{
    height: 100%;
    transform: translateX(0px);
    .delivery-aside__inner{
      height: 100%;
      opacity: 1;
      background-color: #fff;
      box-shadow: 1px 0 4px rgba(0, 0, 0, 0.15);
    }
    .delivery-aside__toggle{
      .mdi{
        &:before{
          content: "\F35E";
        }
      }
    }
  }
  .scrollbar-macosx{
    height: 100%;
    > .scroll-element{
      .scroll-bar{
        opacity: 1;
      }
    }
  }
  &__toggle{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 100%;
    height: 50px;
    width: 40px;
    text-align: center;
    cursor: pointer;
    line-height: 50px;
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.15);
    &:hover{
      background-color: #fafafa;
    }
    .mdi{
      font-size: 24px;
    }
  }
}
.delivery-map{
  height: 100%;
}

.route-popup{
  z-index: 10;
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
  @media(max-width: 670px){
    left: 0;
    width: 100%;
  }
  &.is-show{
    display: block;
  }
  &__close{
    &.btn-icon{
      color: #fff;
      position: absolute;
      right: 6px;
      top: 6px;
      &:hover{
        color:#fff;
      }
    }
  }
  &__body{
    padding: 12px 24px;
    overflow: auto;
    height: calc(100% - 52px);
    .scrollbar-macosx>.scroll-element.scroll-y .scroll-bar{

    }
  }
  &__header{
    position: relative;
    background-color: $info;
    color: #fff;
    padding: 14px 54px 14px 24px;
  }
  &__title{
    font-size: 16px;
    font-weight: 600;
  }
  &__info{
    font-size: 14px;
    line-height: 1.3;
    padding-right: 10px;
    margin-bottom: 10px;
    // border-bottom: 1px solid #e2e2e2;
  }
  &__address{
    font-weight: 600;
  }
  &__company{
    font-size: 13px;
    margin-bottom: 4px;
    color: #888;
  }
  &__date{

  }
  &__param{
    margin: 0 0 10px;
    font-size: 13px;
    width: 100%;
    table-layout: fixed;
    // background-color: #e9ecef;
    tr{
      &:last-child{
        td,
        th{
          border-bottom: 0;
        }
      }
    }
    td,
    th{
      padding: 2px 6px;
      vertical-align: middle;
      border-bottom: 1px solid #e2e2e2;
      border-right: 1px solid #e2e2e2;
      &:last-child{
        border-right: 0;
      }
    }
    td{

    }
  }
}

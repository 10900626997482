.prize {
  position: relative;
  border-radius: 10px;
  padding: 0 0 20px;
  overflow: hidden;

  &__picture {
    display: block;
    width: 100%;
    height: 0;
    padding-top: 67.5%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-decoration: none;
  }

  &__tools {
    background-color: $gray-100;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.45);
    padding: 5px 10px;
    border-radius: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
    line-height: 0;
    button {
      background-color: transparent;
      border: none;
      padding: 0 3px;
      .svg-icon {
        fill: $gray-800;
      }
    }
  }
  &__price {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__title {
    display: flex;
    align-items: center;
    color: $primary;
    margin: 10px auto 20px;
    width: 90%;
    min-height: 40.8px;
    h5 {
      margin-bottom: 0;
    }
  }

  &__get {
    text-align: center;
    color: $success;
    font-size: 16px;
    padding: 5px 20px;
    width: 90%;
    border-radius: 15px;
    align-self: center;
    border-color: $success;
    margin-bottom: 10px;
    &:hover {
      background-color: $success;
      color: white;
    }
  }

  &__table {
    margin-bottom: -15px;
    font-size: 14px;
    width: 100%;
    .first-row {
      border-bottom: 1px solid $gray-300;
      border-top: 1px solid $gray-300;
    }

    td {
      padding: 10px 20px;
      //border: 1px solid;
    }
    .detail-value {
      font-weight: bold;
      text-align: right;
    }
  }
}

.comment-widgets {
  position: relative;
  margin-bottom: 10px;
}

.comment-widgets .comment-row {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  padding: 15px;
}

.comment-widgets .comment-row:last-child {
  border-bottom: 0;
}

.comment-widgets .comment-row.active,
.comment-widgets .comment-row:hover {
  background: rgba(0, 0, 0, 0.02);
}

.comment-text {
  padding: 15px 15px 15px 20px;
  width: 80%;
}

.comment-text.active .comment-footer .action-icons,
.comment-text:hover .comment-footer .action-icons {
  visibility: visible;
}

.comment-text p {
  max-height: 50px;
  width: 100%;
  overflow: hidden;
}

.comment-footer .action-icons {
  visibility: hidden;
}

.comment-footer .action-icons a {
  padding-left: 7px;
  vertical-align: middle;
  color: #99abb4;
}

.comment-footer .action-icons a.active,
.comment-footer .action-icons a:hover {
  color: #398bf7;
}

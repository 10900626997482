.dropdown,
.dropup {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}
.dropdown__inner{
  padding: 10px 16px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  font-size: 14px;
  color: $body-color;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e2e2;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
  border-radius: 3px;
}

.dropdown-divider {
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #2b2d2f;
  text-decoration: none;
  background-color: #f5f5f5;
}

.dropdown-item.active,
.dropdown-item.active:focus,
.dropdown-item.active:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0275d8;
  outline: 0;
}

.dropdown-item.disabled,
.dropdown-item.disabled:focus,
.dropdown-item.disabled:hover {
  color: #818a91;
}

.dropdown-item.disabled:focus,
.dropdown-item.disabled:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: "progid:DXImageTransform.Microsoft.gradient(enabled = false)";
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 5px 20px;
  font-size: 0.875rem;
  color: #818a91;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 0.3em solid;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}


.open > .dropdown-toggle.focus,
.open > .dropdown-toggle:focus,
.open > .dropdown-toggle:hover,
.show > .dropdown-toggle {

}
.dropdown-item {
  padding: 8px 1rem;
  color: #67757c;
}

.slider-wrapper{
  margin-bottom: 3rem;
  position: relative;
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  @include media-breakpoint-down(md){
    padding-top: 0;
    margin-bottom: 2rem;
  }
  @include media-breakpoint-down(sm){
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  @include media-breakpoint-down(xs){
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
  .swiper-container{
    border-radius: 6px;
    overflow: hidden;
  }
  .swiper-pagination{
    bottom: 0;
    left: 0;
    right: 0;
    .swiper-pagination-bullet{
      margin: 0 8px;
      background-color: #d2e0ec;
      @include media-breakpoint-down(sm){
        margin: 0 10px;
      }
    }
    .swiper-pagination-bullet-active{
      background-color: $primary;
    }
  }
  .swiper-button-next,
  .swiper-button-prev{
    width: 16px;
    height: 26px;
    background-size: 16px 26px;
    top: 50%;
    margin-top: (-0.5625rem - .5rem);
    @include media-breakpoint-down(xs){
      display: none;
    }
    &:focus{
      outline: none;
    }
  }
}

.slider{
  &__item{

  }
  &__title{
    font-weight: bold;
    margin-bottom: 1em;
  }
  &__announce{

  }
  &__more{

  }
}
.block{
  display: flex;
  flex-direction: row;
  width: 100%;
  @media(max-width: 960px){
    flex-direction: column;
  }
  &__side{
    width: 600px;
    @media(max-width: 960px){
      width: 100%;
    }
    ~ .block__container{
      padding-left: 30px;
      @media(max-width: 960px){
        padding-left: 0;
      }
    }
  }
  &__container{
    overflow: hidden;
    width: 100%;
  }
}

.msg-item {
  margin-bottom: 20px;
}
a.msg-item{
  text-decoration: none;
  display: block;
  &:hover{
    .msg-body{
      background-color: darken(#f2f2f2,3%);
      .dwn-aro{
        border-top-color: darken(#f2f2f2,3%);
      }
    }
  }
  .msg-body{
    color: $body-color;
  }
  .author{
    h5{
      color: $body-color;
    }
  }
}
.msg-body {
  background: #f2f2f2;
  padding: 15px;
  font-size: 14px;
  position: relative;
  transition: background-color ease 0.25s;
}

.msg-order {
  position: absolute;
  top: -10px;
  right: 10px;
  background: #999;
  padding: 2px 7px;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 400;
}

.msg-body .dwn-aro {
  width: 0;
  position: absolute;
  bottom: -8px;
  height: 0;
  left: 10px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #f2f2f2;
  transition: border-top-color ease 0.5s;
}

.m-pic img {
  width: 40px;
  border-radius: 100%;
}
.m-pic{
  .user-text{
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

.news {
  background-color: #fff;
  margin-bottom: 2rem;
  border-radius: 8px;
  border: 1px solid $table-border-color;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  transition: box-shadow ease 0.25s;
  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05),
      0 4px 4px rgba(0, 0, 0, 0.05), 0 8px 8px rgba(0, 0, 0, 0.05),
      0 16px 16px rgba(0, 0, 0, 0.05);
    // box-shadow: 0 10px 40px 0 rgba(0,46,103,.07), 0 2px 9px 0 rgba(0,46,103,.06);
  }
  &__picture {
    display: block;
    width: 100%;
    height: 0;
    padding-top: 75%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    text-decoration: none;
    border-bottom: 1px solid $border-color;
  }

  &__body {
    padding: 1rem 1rem 1rem;
  }

  &__title {
    font-size: 16px;
    font-weight: font-weight(medium);
    line-height: 1.3;
    margin-bottom: 0;

    a {
      text-decoration: none;
      color: $body-color;
      &:hover {
        color: $link-hover-color;
      }
    }
  }

  &__date {
    color: $gray-600;
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: -5px;
  }
}

.news-detail {
  .card {
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
  }

  &__picture {
    position: relative;
    margin-top: -1rem;
    margin-left: -$card-spacer-x;
    margin-right: -$card-spacer-x;
    padding-top: 56.25%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 16px;
    border-radius: $card-border-radius $card-border-radius 0 0;
  }

  &__title {
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }
  }

  &__date {
    color: $gray-600;
    margin-bottom: 1rem;
  }

  &__body {
    margin-bottom: 2rem;
  }
}

.c-footer {
  bottom: 0;
  color: #67757c;
  left: 0px;
  padding: 17px 15px;
  position: absolute;
  right: 0;
  border-top: 1px solid #ddd;
  background: #dae6f4;
}

.footer {
  &.footer-bottom {
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  color: $gray-600;
  padding: 8px 25px;
  border-top: 1px solid $border-color;
  background-color: #dae6f4;
  margin-top: auto;

  background-repeat: no-repeat;
  background-position: left bottom;

  @include media-breakpoint-down(sm) {
    padding-left: 15px;
    padding-right: 15px;
    background-size: auto;
  }
  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 360px) {
      flex-direction: column;
    }
  }

  &__left {
    flex: 1 0 auto;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 32px;
    padding-left: 32px;

    @media (max-width: 840px) {
      padding-left: 0;
    }

    @media (max-width: 360px) {
      padding-right: 0;
    }
  }
  &__middle {
    padding-right: 32px;
    padding-top: 8px;
    padding-bottom: 8px;
    @media (max-width: 360px) {
      padding-right: 0;
    }
    .phones {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      line-height: 1.3;
      a{
        color: #3f4fa3;

        transition: all .3s;
        &:hover{
          color: #2c3771;
        }

      }

      @media (max-width: 360px) {
        flex-direction: column;
      }
      > div {
        margin-right: 24px;

        @media (max-width: 360px) {
          margin-right: 0;
          margin-bottom: 8px;
        }
        &:last-child {
          margin-right: 0;
          @media (max-width: 360px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &__right {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .apps {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 360px) {
      flex-direction: column;
    }

    > div {
      margin-right: 16px;
      @media (max-width: 360px) {
        margin-right: 0;
        margin-bottom: 8px;
      }
      &:last-child {
        margin-right: 0;
        @media (max-width: 360px) {
          margin-bottom: 0;
        }
      }
    }
    .svg-icon {
      font-size: 40px;
    }
  }
}
.footer-logo {
  width: 80px;
}

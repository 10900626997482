.tags{

}
.card-title{
  .add-tags{
    margin-left: 8px;
  }
  .tags{
    margin-top: 4px;
  }
}
.add-tags{
  vertical-align: middle;
  display: inline-block;
  .dropdown-toggle{
    font-size: 14px;
    font-weight: normal;
    &:hover{
      text-decoration: none;
      color: $link-hover-color;
    }
  }
  &__list{
    overflow: auto;
    max-height: 186px;
  }
}
.tag{
  background-color: #e2e2e2;
  padding: 2px 8px 2px;
  border-radius: 3px;
  display: inline-block;
  text-decoration: none;
  color: $body-color;
  margin-right: 4px;
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 13px;
  font-weight: normal;
  transition: all ease 0.25s;
  line-height: 1.3;
  &.bg-danger,
  &.bg-success,
  &.bg-primary,
  &.bg-info,
  &.bg-warning{
    color: #fff;
    &:hover{
      color: #fff;
    }
  }
  &:hover{
    color: $body-color;
    text-decoration: none;
    opacity: 0.8;
  }
  .mdi{
    margin-left: 4px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 1;
  }
}
.colors{
  > span{
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 4px;
    cursor: pointer;
    position: relative;
    border-radius: 2px;
    &.active{
      &.bg-default{
        &:before{
          color: #333;
        }
      }
      &:before{
        content: "\F12C";
        font: 18px/1 "Material Design Icons";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #fff;
      }
    }
  }
}
a.link{
  color: $primary;
}
.mdi-order{
  &:before{
    content: "\F110";
  }
}
.mdi-request{
  &:before{
    content: "\F755";
  }
}

.p-relative {
  position: relative;
}
.sum,
.price{
  white-space: nowrap;
}
.lstick {
  width: 2px;
  background: $secondary;
  height: 30px;
  margin-left: -20px;
  margin-right: 18px;
  display: inline-block;
  vertical-align: middle;
}

.calendar-events {
  padding: 8px 10px;
  border: 1px solid #ffffff;
  cursor: move;
}

.calendar-events:hover {
  border: 1px dashed rgba(120, 130, 140, 0.13);
}

.calendar-events i {
  margin-right: 8px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  min-height: 194px;
  @media(max-width: 767px){
    min-height: 210px;
  }
  .table{
    margin-bottom: 0;
  }
}

.single-column .left-sidebar {
  display: none;
}

.single-column .page-wrapper {
  margin-left: 0 !important;
}

.customtab li a.nav-link,
.profile-tab li a.nav-link {
  border: 0;
  padding: 15px 20px;
  color: #67757c;
}

.profile-tab li a.nav-link.active {
  border-bottom: 2px solid #398bf7;
  color: #398bf7;
}

.profile-tab li a.nav-link:hover {
  color: #398bf7;
}

html body .flotTip,
html body .jqstooltip {
  width: auto !important;
  height: auto !important;
  background: #263238;
  color: #ffffff;
  padding: 5px 10px;
}

body .jqstooltip {
  border-color: transparent;
  border-radius: 60px;
}

.card {
  margin-bottom: 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  &.is_stuck{
    z-index: 1;
  }
  .card-subtitle {
    font-weight: 300;
    margin-bottom: 15px;
    color: #99abb4;
  }
  .card-body {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .btn-group {
      .btn-icon {
        + .btn-group{
          margin-left: 6px;
        }
      }
    }
  }
  h6.card-title{
    line-height: 1.2;
    margin-bottom: 6px;
    .lstick{
      height: 20px;
    }
  }
  .filter-find{
    display: block;
    font-size: 13px;
    color: #888;
  }
  h4.card-title{
    display: flex;
    align-items: center;
  }
  .card-title {
    font-size: 16px;
    position: relative;
    font-weight: 600;
    .float-right{
      margin-left: auto;
    }
    h4{
      font-size: 16px;
      position: relative;
      font-weight: 600;
      display: inline;
      vertical-align: middle;
      margin-bottom: 0;
    }
  }
  .card-bottom {
    padding-top: 20px;
  }
}

// XS
.card.card-xs{
  .card-header{
    padding: 4px 1.25rem;
    .btn-icon{
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 20px;
    }
  }
  .card-title{
    font-size: 15px;
  }
  .table{
    tr{
      td,
      th{
        font-size: 13px;
        line-height: 1.2;
      }
    }
  }
}
.card-body.p-0{
  .card-title{
    padding: 1rem 1.25rem;
    margin-bottom: 0;
  }
}
.card.card-no-pad{
  .card-body{
    padding: 0;
    .table{
      margin-bottom: 0;
    }
  }
}

.card-header{
  .card-title{
    margin: 0;
  }
  .lstick{
    background: none;
  }
  .d-flex{
    align-items: center;
  }
}

.card-inverse .card-bodyquote .blockquote-footer,
.card-inverse .card-link,
.card-inverse .card-subtitle,
.card-inverse .card-text {
  color: rgba(255, 255, 255, 0.65);
}

.card-default .card-header {
  background: #ffffff;
  border-bottom: 0;
  .card-title{
    color: #fff;
    a{
      color: #fff;
    }
  }
}
/*==============================================================
 Cards page
 ============================================================== */

.card-actions {
  float: right;
}

.card-actions a {
  cursor: pointer;
  color: $gray-500;
  opacity: 0.7;
  padding-left: 7px;
  font-size: 13px;
}

.card-actions a:hover {
  opacity: 1;
}

.card-columns .card {
  margin-bottom: 20px;
}

.collapsing {
  transition: height 0.08s ease;
}

.card-outline-info {
  border-color: $gray-500;
  .card-header {
    background: $gray-400;
    border-color: $gray-500;
    .btn-icon,
    .btn-icon:hover{
      color: $body-color;
    }
    .card-title{
      color: $body-color;
      a{
        color: $body-color;
      }
    }
  }
}

.card-outline-inverse {
  border-color: $body-color;
  .card-header {
    background: $body-color;
    border-color: $body-color;
    .btn-icon,
    .btn-icon:hover{
      color: #fff;
    }
    .card-title{
      color: #fff;
      a{
        color: #fff;
      }
    }
  }
}

.card-outline-warning {
  border-color: #fbe3d0;
  .card-header {
    background: #fbe3d0;
    border-color: #fbe3d0;
    .btn-icon,
    .btn-icon:hover{
      color: $body-color;
    }
    .card-title{
      color: $body-color;
      a{
        color: $body-color;
      }
    }
  }
}

.card-outline-success {
  border-color: #c3f3ce;
  .card-header {
    background: #c3f3ce;
    border-color: #c3f3ce;
    .btn-icon,
    .btn-icon:hover{
      color: $body-color;
    }
    .card-title{
      color: $body-color;
      a{
        color: $body-color;
      }
    }
  }
}

.card-outline-danger {
  border-color: $danger;
  .card-header {
    background: $danger;
    border-color: $danger;
    .btn-icon,
    .btn-icon:hover{
      color: #fff;
    }
    .card-title{
      color: #fff;
      a{
        color: #fff;
      }
    }
  }
}
.card-outline-primary {
  border-color: $primary;
  .card-header {
    background: $primary;
    border-color: $primary;
    .btn-icon,
    .btn-icon:hover{
      color: #fff;
    }
    .card-title{
      color: #fff;
      a{
        color: #fff;
      }
    }
  }
}

.card-box{
  display: inline-block;
  margin-bottom: 2rem;
  // background-color: $gray-100;
  border-radius: $border-radius;
  border: 1px solid $primary;
}
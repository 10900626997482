.news-list{
  &__item:not(:last-of-type){
    margin-bottom: 16px;
  }
  &__title{
    margin-bottom: 5px;
  }
  &__date{
    color: $text-muted;
  }
}

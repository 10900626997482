[data-notify="container"] {
  width: 210px;
  padding: 20px 16px 20px;
  border-radius: 3px;
  .btn-close{
    position: absolute;
    right: 5px;
    top: 11px;
    color: #fff;
    &:hover{
      color: #000;
    }
  }
  &.alert-success {
    color: #fff;
    background-color: $danger;
    border-color: $danger;
  }
  &.alert-danger {
    color: #fff;
    background-color: $danger;
    border-color: $danger;
  }
}

[data-notify="icon"] {

}

[data-notify="message"] {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  display: block;
  color: #fff;
  line-height: 1.2;
}


.notify {
  position: relative;
  top: -28px;
  right: -7px;
  &__text{
    position: absolute;
    top: -16px;
    right: -4px;
    height: 20px;
    width: 20px;
    z-index: 10;
    font-size: 10px;
    background-color: $danger;
    color: #fff;
    border-radius: 20px;
    line-height: 20px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0,0,0,.2);
  }

  .heartbit {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid $danger;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
  }
  .point {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background-color: $danger;
    position: absolute;
    right: 6px;
    top: -10px;
  }
}
@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0.0;
  }

  25% {
    -moz-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -moz-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -moz-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1);
    opacity: 0.0;
  }
}
@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0.0;
  }
}

$aside-width-lg: 320px !default; // 420
$aside-width: 320px !default; // 320
$aside-width-sm: 300px !default; // 380

.catalog-wrapper {
  width: 100%;
}
.catalog-aside {
  position: fixed;
  top: 70px;
  height: calc(100% - 70px);
  width: $aside-width;
  overflow: auto;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  @media (min-width: 1200px) {
    width: $aside-width-lg;
  }
  @media (max-width: 960px) {
    right: 0;
    z-index: 10;
    display: none;
  }
  @media (max-width: 460px) {
    width: 100%;
  }
  @media (min-width: 961px) {
    display: block !important;
  }
  &__inner {
    height: 100%;
    .card {
      margin-bottom: 0;
      border-radius: 0;
      border-top: 0;
      border-bottom: 0;
      height: 100%;
    }
    .scroll-wrapper {
      max-height: 100% !important;
      height: 100% !important;
    }
  }
}
.catalog-container {
  &.catalog-min-height {
    min-height: 835px;
  }
  .grey {
    background-color: #ECECEC;
  }
  
  margin-left: $aside-width;
  display: flex;
  height: 100%;
  flex-direction: column;
  @media (max-width: 960px) {
    margin-left: 0;
  }
  @media (min-width: 1200px) {
    margin-left: $aside-width-lg !important;
  }
  @media (min-width: 961px) {
    margin-left: $aside-width !important;
  }
  .footer {
    position: relative;
    margin-bottom: -60px;
    margin-top: auto;
  }
}

.mini-sidebar {
  .catalog-aside {
    width: $aside-width-sm;
    @media (min-width: 1200px) {
      width: $aside-width-sm + 100;
    }
    @media (max-width: 460px) {
      width: 100%;
    }
  }
  .catalog-container {
    margin-left: $aside-width-sm;
    @media (max-width: 960px) {
      margin-left: 0;
    }
    @media (min-width: 1200px) {
      margin-left: $aside-width-sm + 100 !important;
    }
    @media (min-width: 961px) {
      margin-left: $aside-width-sm !important;
    }
  }
}

.good-picture {
  display: inline-block;
  text-decoration: none;
  img {
    max-width: 60px;
    max-height: 60px;
  }
  &:hover {
    text-decoration: none;
  }
}

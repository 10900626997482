.c-select {
  position: relative;
  &.is-disabled{
    opacity: .5;
  }
  select{
    &:focus + .c-select__trigger{
      &.is-open{
        box-shadow: none;
      }
    }
  }
  &__trigger{
    border-radius: 2px;
    font-size: 14px;
    cursor: pointer;
    padding: 6px 26px 7px 12px;
    height: 35px;
    line-height: $line-height-base;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    background: #fff;
    border: 1px solid $gray-400;
    transition: all ease-out 0.25s;
    &:after{
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: $gray-400;
      top: 50%;
      margin-top: -2px;
      right: 9px;
    }
    &.is-open{
      border: 1px solid $gray-400;
      &:after{
        border-top-color: $primary;
      }
    }
  }
  &__options{
    background-color: #fff;
    list-style: none;
    min-width: 200px;
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 35px;
    left: auto;
    right: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 11;
    max-height: 200px;
    overflow: auto;
    border-radius: 2px;
    border: 1px solid $gray-400;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
    &.is-open{
      visibility: visible;
      top: (35px + 2);
      opacity: 1;
      transition: opacity 300ms ease-out, top 300ms ease-out;
    }
    &.c-select--up{
      top: auto;
      bottom: (35px + 2);
      transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
      &.is-open{
        top: auto;
        bottom: (35px + 2);
        transition: opacity 300ms ease-out, bottom 300ms ease-out;
      }
    }
    li{
      padding: 6px 12px;
      font-size: 14px;
      cursor: pointer;
      margin: 0;
      white-space: nowrap;
      transition: all 150ms ease-out;
      &:hover{
        background-color: #f1f1f1;
      }
      &.is-selected{
        background: $primary;
        color: #ffffff;
      }
    }
  }
}

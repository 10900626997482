.chat-list {
  margin: 0;
  padding: 20px;
  min-height: 300px!important;
  // max-height: 500px!important;
  @include media-breakpoint-down(xs){
    padding: 20px 15px;
    max-height: 400px!important;
  }
  li{
    list-style: none;
    margin-top: 20px;
    position: relative;
    font-size: 0;
    .chat-img {
      display: inline-block;
      width: 45px;
      vertical-align: top;
      .user-text{
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 16px;
      }
      img {
        width: 45px;
        border-radius: 100%;
      }
    }
    .chat-content {
      font-size: 14px;
      width: calc(100% - 105px);
      display: inline-block;
      padding-left: 15px;
      @include media-breakpoint-down(xs){
        width: calc(100% - 45px);
      }
      h5 {
        color: #888;
        font-size: 15px;
        @include media-breakpoint-down(xs){
          font-size: 14px;
        }
      }
      .box {
        display: inline-block;
        margin-bottom: 15px;
        color: #263238;
        @include media-breakpoint-down(xs){
          font-size: 13px;
          margin-bottom: 20px;
        }
      }
    }
    .chat-time {
      display: inline-block;
      text-align: right;
      width: 60px;
      font-size: 13px;
      color: #999999;
      .mdi{
        font-size: 15px;
      }
      @include media-breakpoint-down(xs){
        position: absolute;
        right: 0;
        line-height: 1.2;
        bottom: 2px;
        font-size: 11px;
      }
    }
    .chat-date{
      display: inline-block;
      vertical-align: middle;
      font-size: 13px;
      color: #999999;
      background-color: #fff;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
    }
    &.odd{
      .chat-content {
        text-align: right;
        width: calc(100% - 70px);
      }
      .box {
        clear: both;
      }
      + .odd {
        margin-top: 0;
      }
    }
    // section
    &.chat-section{
      text-align: center;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #ddd;
        top: 50%;
      }
    }
    // reverse
    &.reverse {
      text-align: right;
      ~ .reverse{
        margin-top: 0;
      }
      .chat-time {
        text-align: right;
      }
      .chat-content {
        width: calc(100% - 120px);
        padding-left: 0;
        padding-right: 0;
        @include media-breakpoint-down(xs){
          width: calc(100% - 60px);
        }
      }
    }
  }
}

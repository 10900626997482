.left-sidebar {
  position: absolute;
  width: 240px;
  height: 100%;
  top: 0;
  z-index: 20;
  padding-top: 70px;
  background-color: #fff;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
}

.fix-sidebar .left-sidebar {
  position: fixed;
}

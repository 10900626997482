$checkbox-and-radio-width:           20px !default;//                .checkbox & .radio
$checkbox-and-radio-border-color:    #6D6D6D !default;// .checkbox & .radio
$checkbox-and-radio-bg-checked:      $primary !default;//
$checkbox-and-radio-color-disabled:  #b2b2b2 !default;

.checkbox {
  margin-bottom: 0;
  padding-left: ($checkbox-and-radio-width + 8px);
  color: $body-color;
  margin-top: 6px;
  cursor: pointer;
  position: relative;
  text-transform: none;
  &__group{
    .c-checkbox{
      display: block;
    }
    .checkbox + .checkbox{
      margin-top: 16px;
    }
  }
  span {
    transition: all ease 0.25s;
    // white-space: nowrap;
    font-weight: normal;
  }

  i {
    position: absolute;
    top: 2px;
    //margin-top: -($checkbox-and-radio-width/2);
    left: 0;
    display: block;
    width: $checkbox-and-radio-width;
    height: $checkbox-and-radio-width;
    outline: none;
    border: 2px solid $checkbox-and-radio-border-color;
    border-radius: 2px;
    background-color: #fff;
    transition: all ease 0.25s;
  }

  input {
    position: absolute;
    left: -9999px;
    appearance: none;

    ~ i {
      &:after {
        content: '';
        position: absolute;
        transition: background-color 0.1s, border-color 0.1s;
        border-radius: 2px;
        border: 2px solid $checkbox-and-radio-border-color;
        top: -2px;
        left: -2px;
        width: $checkbox-and-radio-width;
        height: $checkbox-and-radio-width;
        line-height: $checkbox-and-radio-width - 2;
        text-align: center;
        background-color: #fff;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: $checkbox-and-radio-width;
      }
    }

    &:checked {
      ~ i {
        &:after {
          background-color: $checkbox-and-radio-bg-checked;
          border-color: $checkbox-and-radio-bg-checked;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGZpbGw9IiNmZmZmZmYiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEsN0w5LDE5TDMuNSwxMy41TDQuOTEsMTIuMDlMOSwxNi4xN0wxOS41OSw1LjU5TDIxLDdaIiAvPjwvc3ZnPg==);
          font-size: 12px;
          font-style: normal;
          color: #fff;
        }
      }

      &:disabled {
        ~i {
          cursor: not-allowed;

          &:after {
            border-color: $checkbox-and-radio-color-disabled;
            background-color: $checkbox-and-radio-color-disabled;
          }
        }
      }
    }

    &:disabled {
      ~ i {
        cursor: not-allowed;

        &:after {
          border-color: $checkbox-and-radio-color-disabled;
        }
      }

      ~ span {
        color: $checkbox-and-radio-color-disabled;
      }
    }
  }
}

.error-page{
  height: 100%;
}

.error-box {
  height: 100%;
  position: fixed;
  width: 100%;
  @include media-breakpoint-down(xs){
    background-size: 80%;
  }
  .footer {
    width: 100%;
    left: 0px;
    right: 0px;
    bottom: 0;
    color: #fff;
    padding: 17px 15px;
    position: absolute;
    right: 0;
    border-top: 1px solid rgba(#f2f2f2, 0.1);
  }
}

.error-body {
  padding-top: 5%;
  padding-left: 30px;
  padding-right: 30px;
  p{
    color: $body-color;
  }
  h1 {
    color: #fff;
    font-size: 210px;
    font-weight: 900;
    text-shadow: 4px 4px 0 $primary, 6px 6px 0 #fff;
    line-height: 210px;
    @include media-breakpoint-down(md){
      font-size: 180px;
      line-height: 180px;
    }
    @include media-breakpoint-down(sm){
      font-size: 140px;
      line-height: 140px;
    }
    @include media-breakpoint-down(xs){
      font-size: 120px;
      line-height: 120px;
    }
  }
}

.r-aside {
  padding-right: 300px;
}

.right-side-panel {
  width: 275px;
  padding: 20px 20px 70px;
  background: #e1e6ea;
  position: absolute;
  right: 0;
  top: 77px;
  height: calc(100% - 77px);
  overflow: hidden;
}
@media (max-width: 1023px) {
  .r-aside {
    padding-right: 25px;
  }

  .right-side-panel {
    display: none;
  }
}
@media (max-width: 767px) {
  .r-aside {
    padding-right: 15px;
  }

  #visitor {
    text-align: center;
  }
}

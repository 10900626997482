$default: #007bff;
$default_dark: #0562c7;

// $success: #28a745;
$success_dark: darken($success, 5%);

// $danger: #dc3545;
$danger_dark: darken($danger, 5%);

// $info: #17a2b8;
$info_dark: darken($info, 5%);

// $warning: #eab000;
$warning_dark: darken($warning, 5%);

$awesome: #685dc3;
$awesome_dark: #4c3fb1;

$default_timer: #007bff;
$default_timer_filler: #fff;

$success_timer: $success;
$success_timer_filler: #fff;

$danger_timer: $danger;
$danger_timer_filler: #fff;

$info_timer: $info;
$info_timer_filler: #fff;

$warning_timer: $warning;
$warning_timer_filler: #fff;

$awesome_timer: #685dc3;
$awesome_timer_filler: #fff;
.form-rating {

}

.stars-field {
  margin: 0;
  padding: 0;
  border: none;
  display: inline-block;
  user-select: none;
  font-size: 0;

  &__input {
    display: none;
  }

  &__label {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 168 20'%3E%3Cpath fill='%23DDD' fill-rule='evenodd' d='M168 8l-7-1-3-7-4 7-7 1 6 4-2 8 7-3 6 3-2-8zM13.52 6.781L10.514 0 7.511 6.781 0 7.641l5.654 4.855L4.016 20l6.498-3.048 6.5 3.048-1.638-7.504 5.654-4.855zm36.75 0L47.265 0l-3.004 6.781-7.51.86 5.654 4.855L40.767 20l6.498-3.048 6.5 3.048-1.638-7.504 5.654-4.855zm36.75 0L84.016 0 81.01 6.781l-7.51.86 5.654 4.855L77.518 20l6.498-3.048 6.5 3.048-1.638-7.504 5.652-4.855zm44.26.859l-7.51-.859L120.765 0l-3.004 6.781-7.51.86 5.654 4.855L114.267 20l6.498-3.048L127.263 20l-1.636-7.504z'/%3E%3C/svg%3E");
    cursor: pointer;
    float: right;
    background-size: 168px 20px;
    width: 20px;
    height: 21px;
    padding: 0;
    margin: 0 14px 0 0;
  }
}


.stars-field:not(:checked) > .stars-field__label:hover,
.stars-field:not(:checked) > .stars-field__label:hover ~ .stars-field__label,
.stars-field__input:checked + .stars-field__label:hover,
.stars-field__input:checked ~ .stars-field__label,
.stars-field__input:checked ~ .stars-field__label:hover,
.stars-field__input:checked ~ .stars-field__label:hover ~ .stars-field__label,
.stars-field__label:hover ~ .stars-field__input:checked ~ .stars-field__label {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 168 20'%3E%3Cpath fill='%23c22e59' fill-rule='evenodd' d='M168 8l-7-1-3-7-4 7-7 1 6 4-2 8 7-3 6 3-2-8zM13.52 6.781L10.514 0 7.511 6.781 0 7.641l5.654 4.855L4.016 20l6.498-3.048 6.5 3.048-1.638-7.504 5.654-4.855zm36.75 0L47.265 0l-3.004 6.781-7.51.86 5.654 4.855L40.767 20l6.498-3.048 6.5 3.048-1.638-7.504 5.654-4.855zm36.75 0L84.016 0 81.01 6.781l-7.51.86 5.654 4.855L77.518 20l6.498-3.048 6.5 3.048-1.638-7.504 5.652-4.855zm44.26.859l-7.51-.859L120.765 0l-3.004 6.781-7.51.86 5.654 4.855L114.267 20l6.498-3.048L127.263 20l-1.636-7.504z'/%3E%3C/svg%3E");
}
.table-align-middle {
  > tbody,
  > thead,
  > tfoot {
    > tr {
      > td,
      > th {
        vertical-align: middle;
      }
    }
  }
}
.table{
  thead,
  tfoot{
    th{
      background-color: $table-head-bg;
    }
  }
}
.table-success,
.table-success>td,
.table-success>th {
  background-color: lighten($success,45%);
}
.table-hover .table-success:hover,
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th{
  background-color: lighten($success,40%);
}
.table-danger,
.table-danger>td,
.table-danger>th {
  background-color: lighten($danger,40%);
}
.table-hover .table-danger:hover,
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th{
  background-color: lighten($danger,35%);
}
.tablesaw-overflow{
  .table{
    margin-bottom: 0;
  }
}
.table {
  tr.is-over {
    color: #ccc;
  }
  th {
    font-weight: 600;
    line-height: 1.2;
    border-top: 0;
    border-bottom-width: 1px;
  }
  &.table-sm {
    td,
    th{
      line-height: 1.3;
    }
    td{
      padding: 8px;
    }
    th{
      font-size: 13px;
      padding: 8px;
    }
  }
  &.table-xs{
    td,
    th{
      line-height: 1.3;
    }
    th{
      font-weight: 600;
      padding: 8px;
      font-size: 12px;
    }
    td{
      padding: 4px 8px;
      font-size: 12px;
    }
    tfoot{
      th{
        padding: 8px;
      }
    }
  }
}

.tab-pane{
  .table{
    margin-bottom: 2em;
  }
}

.table-drag{
  tr{
    td,
    th{
      vertical-align: middle;
    }
    &:hover{
      .drag{
        color: #888;
      }
    }
  }
}
.drag{
  font-size: 22px;
  color: #bbb;
  cursor: move;
}

.drag-block{
  &__top{
    max-height: 318px;
  }
  &__bottom{
    max-height: 318px;
  }
}
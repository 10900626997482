.breadcrumb {
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  color: #888;
  font-size: 0;
  &__item {
    font-size: 14px;
    display: inline-block;
    margin: 0;
    position: relative;
    padding-right: 20px;
    + .breadcrumb__item:after,
    + .breadcrumb__item:before{
      content: "";
      background-color: #888;
      display: block;
      position: absolute;
      width: 1px;
      height: 6px;
      left: -10px;
      top: 50%;
    }
    + .breadcrumb__item:before {
      transform: rotate(-45deg);
      margin-top: -5px;
    }
    + .breadcrumb__item:after {
      transform: rotate(45deg);
      margin-top: -1px;
    }
  }
  &__link {
    color: $body-color;
  }
}

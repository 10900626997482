.slider{
  margin-bottom: 1rem;
  &__item{
    background-color: #fff;
    // height: 600px;
    // text-align: center;
    @include media-breakpoint-down(sm){
      height: auto;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 600px;
      @include media-breakpoint-down(sm){
        width: auto;
        height: auto;
      }
    }
  }
}

.modal{
  .slider-wrapper{
    margin-bottom: 0;
  }
}

.slider-thumbs{
  padding: 0 48px;
  .swiper-button-prev{
    left: 0;
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
    &:before{
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'#{$primary}'%2F%3E%3C%2Fsvg%3E");
    }
  }
  .swiper-button-next{
    right: 0;
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);  
    &:before{
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'#{$primary}'%2F%3E%3C%2Fsvg%3E");
    }
  }
  .swiper-button-prev,
  .swiper-button-next{
    width: 30px;
    margin-top: 0;
    top: 0;
    height: 100%;
    &:before{
      background-size: 30px 30px;
      background-repeat: no-repeat;
      background-position: center;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  .swiper-slide-thumb-active{
    .slider-thumbs__item{
      opacity: 1;
      border: 1px solid $primary;
      border-radius: 6px;
    }
  }
  &__item{
    opacity: 0.5;
    cursor: pointer;
    transition: opacity ease 0.25s;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    // background-color: $gray-200;
    &:hover{
      opacity: 1;
    }
  }
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: em(8px, 14px);
  font-size: 14px;
}

input[type="range"] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

output {
  display: block;
  padding-top: 4px;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $input-color;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea {
  display: block;
  width: 100%;
  height: $input-height;
  padding: 6px 10px;
  font-size: 14px;
  line-height: $line-height-base;
  color: $input-color;
  background-color: $input-bg;
  background-image: none;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  // Placeholder
  // @include placeholder;
  // @include input-focus;
  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    border: 0;
    background-color: transparent;
  }

  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: $input-disabled-bg;
    opacity: 1;
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}

textarea {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="time"] {
    line-height: $line-height-base;
  }
}

input[type="checkbox"],
input[type="radio"] {
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}

input[type="file"] {
  display: block;
  width: 100%;
  height: 35px;
}

.form-group{
  &.size-xs,
  &.size-sm,
  &.size-md,
  &.size-lg{
    margin-right: 1rem;
  }
  &.size-xs{
    select,
    .form-control{
      display: block;
      width: 120px;
    }
  }
  &.size-sm{
    @include media-breakpoint-down(xs){
      margin-right: 0;
      width: 100%;
    }
    select,
    .form-control{
      width: 256px;
      display: block;
      @include media-breakpoint-down(xs){
        width: 100%;
      }
    }
  }
  &.size-md{
    @include media-breakpoint-down(xs){
      margin-right: 0;
      width: 100%;
    }
    select,
    .form-control{
      width: 392px;
      display: block;
      @include media-breakpoint-down(xs){
        width: 100%;
      }
    }
  }
  &.size-lg{
    @include media-breakpoint-down(sm){
      margin-right: 0;
      width: 100%;
    }
    .dropzone{
      display: flex;
      width: 528px;
      @include media-breakpoint-down(sm){
        width: 100%;
      }
    }
    select,
    .form-control{
      display: block;
      width: 528px;
      @include media-breakpoint-down(sm){
        width: 100%;
      }
    }
  }
  &.size-full{
    width: 100%;
    select,
    .form-control{
      display: block;
      width: 100%;
    }
  }
}